import "./App.css";
import { NavBar } from "./componenets/navbar/navbar";
import { ShoppingCart } from "./componenets/ShoppingCart/shoppingCart";
import { Home } from "./componenets/HomePage/home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Checkout } from "./componenets/ShoppingCart/checkOut";
import { OrderDetail } from "./componenets/ShoppingCart/orderDetail";
import { CategoryPage } from "./componenets/categoryPage/categorypage";
import { Footer } from "./componenets/HomePage/footer";
import { ProductPage } from "./componenets/productPage/productPage";
import { DiscountList } from "./componenets/discountPage/discountList";
import React, { useState } from "react";
import { SideCardShoppingCart } from "./componenets/ShoppingCart/sideCardShoppingCart";
import { ContactPage } from "./componenets/contact/contactPage";
import { Brandshowcase } from "./componenets/BrandShowcase/brandShowcase";
import { PrivacyPolicy } from "./componenets/footerContent/privacyPolicy";
import { Menu } from "./componenets/navbar/menu";
import { LocalStorageService } from "./service/LocalStorageService";
import { Search } from "./componenets/search/search";
import ScrollToTop from "./componenets/ScrollToTop";
import { Product } from "./service/interfaces";
import { Order } from "./service/interfaces";
import { ErrorPage } from "./componenets/errorPage";
import { OrderBody } from "./service/interfaces";
import axios from "axios";

function App() {
  const [state, setState] = useState({
    sideCartStatus: false,
    shoppingCart:
      LocalStorageService.fetchFromLocalStorage("shoppingCart") ?? [],
  });
  const [searchState, setSearchState] = useState(false);

  function sendOrder(
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber:string,
    address: string,
    municipality: string,
    city: string,
    postalCode: string
  ) {
    if (state.shoppingCart.length > 0) {
      let total = 0;
      state.shoppingCart.map((order: Order) => {
        total =
          total +
          (order.Price * order.Quantity -
            order.Price * order.Quantity * ((order.Discount as number) / 100));
      });

      const orderBody: OrderBody = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        address: address,
        municipality: municipality,
        city: city,
        postalCode: postalCode,
        items: state.shoppingCart,
        total: Math.round(total),
      };

      console.log(orderBody);

      // axios.post("https://cipasport.mk/api/sendOrder", orderBody);
      // axios.post("http://localhost:8788/api/sendOrder", orderBody);
      axios({
        method: 'post',
        url: '/api/sendOrder',
        data: orderBody,
        headers: {
            'Content-Type': 'text/plain;charset=utf-8',
        },
    }).then(function (response) {
        console.log(response);
    }).catch(function (error) {
        console.log(error);
    });
    }
  }
  function closeSideCard() {
    const newState = { ...state };
    newState.sideCartStatus = false;
    setState(newState);
  }
  function openSearch() {
    setSearchState(true);
  }
  function closeSearch() {
    setSearchState(false);
  }

  function openSideCard(
    id: string,
    selectedSize: string,
    quantity: number,
    categoryProducts: Product[]
  ) {
    setState((oldState) => {
      const findProduct = categoryProducts.find((p) => p.Product_Code === id);

      const orderPrice = Math.round(
        (findProduct?.Price as number) -
          (findProduct?.Price as number) *
            ((findProduct?.Discount as number) / 100)
      );

      const order = {
        ...findProduct,
        Price: orderPrice,
        Size: selectedSize,
        Quantity: quantity,
      };

      if (!findProduct) return oldState;

      if (
        oldState.shoppingCart.find(
          (p: Order) =>
            p.Product_Code === order?.Product_Code && p.Size === selectedSize
        )
      ) {
        let newCart = oldState.shoppingCart;
        let addToQuant = newCart.find(
          (p: Order) =>
            p.Product_Code === order?.Product_Code && p.Size === selectedSize
        );
        addToQuant.Quantity = parseInt(addToQuant.Quantity) + quantity;

        return {
          ...oldState,
          shoppingCart: newCart,
          sideCartStatus: true,
        };
      }
      // let newOrder: Order;
      let newOrder = order;
      return {
        ...oldState,
        shoppingCart: [newOrder, ...oldState.shoppingCart],
        sideCartStatus: true,
      };
    });
  }

  function removeShoppingCartTerm(id: string | undefined) {
    setState((oldState) => {
      const newCart = oldState.shoppingCart.filter(
        (t: Order) => t.Product_Code !== id
      );
      return {
        ...oldState,
        shoppingCart: newCart,
      };
    });
  }

  return (
    <div className="App scroll-smooth">
      <main>
        <BrowserRouter>
          <ScrollToTop>
            <NavBar
              numCartItems={state.shoppingCart.length}
              search={openSearch}
            />

            <div className="sticky z-10 top-0">
              <Menu
                search={openSearch}
                numCartItems={state.shoppingCart.length}
              />
              <Search status={searchState} closeSearch={closeSearch} />
            </div>

            <SideCardShoppingCart
              Show={state.sideCartStatus}
              Close={closeSideCard}
              ShoppingCart={state.shoppingCart}
              RemoveCartTerm={removeShoppingCartTerm}
            />
            <div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/shopping_cart"
                  element={
                    <ShoppingCart
                      ShoppingCart={state.shoppingCart}
                      RemoveCartTerm={removeShoppingCartTerm}
                    />
                  }
                />
                <Route
                  path="/check_out"
                  element={
                    <Checkout
                      ShoppingCart={state.shoppingCart}
                      RemoveCartTerm={removeShoppingCartTerm}
                      sendOrder={sendOrder}
                    />
                  }
                />
                <Route path="/order_detail" element={<OrderDetail />} />
                <Route
                  path="/category_page/:category/:type"
                  element={<CategoryPage />}
                />
                <Route
                  path="/category_page/:searchTerm"
                  element={<CategoryPage />}
                />
                <Route
                  path="/product_page/:category/:type/:id"
                  element={<ProductPage open={openSideCard} />}
                />
                <Route path="*" element={<ErrorPage />} />
                <Route path="/discount_list" element={<DiscountList />} />
                <Route path="/contact_page" element={<ContactPage />} />
                <Route path="/showcase/:brand" element={<Brandshowcase />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
              </Routes>
            </div>
            <footer>
              <Footer />
            </footer>
          </ScrollToTop>
        </BrowserRouter>
      </main>
    </div>
  );
}
export default App;
