import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { slickCarouselimages as images } from "../../service/constants";
// const settings = {
//   className: "center",
//   dots: true,
//   infinite: true,
//   slidesToShow: 3,
//   autoplay: true,
//   autoplaySpeed: 1500,
//   pauseOnHover: true,
//   arrows: false,
//   centerPadding: "60px",
//   speed: 500,
// };
export const SlickCarousel: React.FC = () => {
  const settings1 = {
    centerMode: true,
    arrows: false,
    dots: true,
    speed: 500,
    className: "center",
    slidesToShow: 3,
    centerPadding: "60px",
  };
  const settings2 = {
    arrows: false,
    dots: true,
    speed: 500,
    slidesToShow: 1,
  };
  return (
    <>
      <div id="main-slider" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 lg:mt-10 mb-28 relative block md:hidden">
        <div className="py-10">
          <Slider {...settings2} className="h-96">
            {images.map((img, indx) => (
              <div
                key={indx}
                className="h-[20rem] my-auto ease-in transition delay-100"
              >
                <Link to={img.imgHref}>
                  <img
                    src={img.imgSrc}
                    className="object-center object-contain h-full mx-auto w-full"
                  />
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div id="main-slider" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 lg:mt-10 mb-28 hidden md:block">
        <Slider {...settings1} className="">
          {images.map((img, indx) => (
            <div className="h-[30rem]">
              <div key={indx} className="h-[20rem] mt-10">
                <Link to={img.imgHref}>
                  <img
                    src={img.imgSrc}
                    className="object-center object-cover h-full mx-auto w-full"
                  />
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
