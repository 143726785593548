import { Link, useParams, useSearchParams } from "react-router-dom";
import { ProductsJson } from "../../service/ProductsJson";
import { BreadCrumbs } from "../breadcrumbs/breadcrumbs";
import { Product } from "../../service/interfaces";

export const DiscountList: React.FC = () => {
  const [searchParams] = useSearchParams();


  const products: Product[] = searchParams.get("category")
    ? ProductsJson.getAllProducts().filter(
        (f: Product) => f.Discount !== 0 && f.Category === searchParams.get("category")
      )
    : ProductsJson.getAllProducts().filter((f: Product) => f.Discount !== 0);

  return (
    <div className="bg-white ">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <BreadCrumbs Pages={[{ name: "Попусти", href: "#", current: true }]} />

        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8 mt-10">
          {products.map((product) => (
            <Link
              key={product.Product_Code}
              to={
                "/product_page/" +
                product.Category.split(" ")[0] +
                "/" +
                product.Category.split(" ")[1] +
                "/" +
                product.Product_Code
              }
              className="group"
            >
              <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8 relative">
                <img
                  src={product.Images[0]}
                  alt={"Slika"}
                  className="w-full h-full object-center object-cover group-hover:opacity-75 relative"
                />
                <div className="absolute top-0 right-0 px-2 py-1 bg-cipa-red-500">
                  <span className="text-white">-{product.Discount}%</span>
                </div>
              </div>
              <h3 className="mt-4 text-sm text-gray-700 font-medium text-right">
                {product.Product_Name}
              </h3>
              <p className="mt-1 text-xs font-medium text-right line-through text-black">
                {(product.Price / 1000).toFixed(3)} Ден
              </p>
              <p className="mt-1 text-lg font-medium text-right text-cipa-red-500">
                {Math.round(
                  product.Price - product.Price * (product.Discount / 100)
                ) / 1000}{" "}
                Ден
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
