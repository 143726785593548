import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { LocalStorageService } from "../../service/LocalStorageService";
import { Link } from "react-router-dom";
import { Order } from "../../service/interfaces";
interface Props {
  Show: boolean;
  Close: () => void;
  ShoppingCart: Order[];
  RemoveCartTerm: (id: string) => void;
}

export const SideCardShoppingCart: React.FC<Props> = (props: Props) => {
  LocalStorageService.saveToLocalStorage("shoppingCart", props.ShoppingCart);
  let Total = 0;
  console.log(props.ShoppingCart);

  props.ShoppingCart.map((p) => {
    Total = Total + p.Price * p.Quantity;
  });
  return (
    <Transition.Root show={props.Show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-20"
        onClose={props.Close}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Кошничка
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                          onClick={props.Close}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <ul
                          role="list"
                          className="-my-6 divide-y divide-gray-200"
                        >
                          {props.ShoppingCart.map((product: Order) => (
                            <li
                              key={product.Product_Code}
                              className="py-6 flex"
                            >
                              <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                                <img
                                  src={product.Images[0]}
                                  className="w-full h-full object-center object-cover"
                                />
                              </div>

                              <div className="ml-4 flex-1 flex flex-col">
                                <div>
                                  <div className="flex justify-between text-base font-medium text-gray-900">
                                    <h3>
                                      <Link to="#">{product.Product_Name}</Link>
                                    </h3>

                                    {(() => {
                                      if (product.Discount === 0) {
                                        if (product.Price > 999) {
                                          return (
                                            <p className="text-base font-medium text-gray-900">
                                              {(product.Price / 1000).toFixed(
                                                3
                                              )}{" "}
                                              Ден
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <p className="text-base font-medium text-gray-900">
                                              {product.Price} Ден
                                            </p>
                                          );
                                        }
                                      } else {
                                        if (product.Price > 999) {
                                          return (
                                            <div>
                                              <p className="text-base font-medium text-cipa-red-500">
                                                - {product.Discount} %
                                              </p>
                                              <p className="text-base font-medium text-cipa-red-500">
                                                {(product.Price / 1000).toFixed(
                                                  3
                                                )}{" "}
                                                Ден
                                              </p>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              <p className="text-base font-medium text-cipa-red-500">
                                                - {product.Discount} %
                                              </p>
                                              <p className="text-base font-medium text-cipa-red-500">
                                                {product.Price} Ден
                                              </p>
                                            </div>
                                          );
                                        }
                                      }
                                    })()}
                                  </div>
                                  {/* <p className="mt-1 text-sm text-gray-500">
                                    Боја: {product.color}
                                  </p> */}
                                  <p className="mt-1 text-sm text-gray-500">
                                    Големина: {product.Size}
                                  </p>
                                </div>
                                <div className="flex-1 flex items-end justify-between text-sm">
                                  <p className="text-gray-500">
                                    Количина: {product.Quantity}
                                  </p>

                                  <div className="flex">
                                    <button
                                      type="button"
                                      className="font-medium text-cipa-red-600 hover:text-cipa-red-500"
                                      onClick={() =>
                                        props.RemoveCartTerm(
                                          product.Product_Code
                                        )
                                      }
                                    >
                                      Отстрани
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Вкупно</p>
                      <p>{(Total / 1000).toFixed(3)} Ден</p>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">
                      {/* Shipping and taxes calculated at checkout. */}
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/check_out"
                        className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cipa-blue-600 hover:bg-cipa-blue-400"
                        onClick={props.Close}
                      >
                        Нарачај
                      </Link>
                    </div>
                    <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
                      <p>
                        или{" "}
                        <button
                          type="button"
                          className="text-cipa-blue-600 font-medium hover:text-cipa-blue-500"
                          onClick={props.Close}
                        >
                          продолжете со шопинг
                          <span aria-hidden="true"> &rarr;</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
