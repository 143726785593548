import { MailIcon, PhoneIcon } from "@heroicons/react/outline";

export const ContactPage: React.FC = () => {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 px-4 sm:px-6 lg:col-span-2 lg:px-8 xl:pr-12">
          <div className="max-w-lg mx-auto mt-5 lg:mt-20">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Контакт
            </h2>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>Страшо Пинџур 51</p>
                  <p>Тетово</p>
                  <p>Работоно време</p>
                  <p>Пон - Саб : 09:00 - 20:00</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Телфонски број</dt>
                <dd className="flex justify-center">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">(+389) 044 334 040</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex justify-center">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">contact@cipasport.mk</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="bg-white px-4 sm:px-6 lg:col-span-3  lg:px-8 xl:pl-12">
          <iframe
            className="my-10 w-full"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2492.8197364605076!2d20.96969747227207!3d42.01145639084228!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xca3d8efaf44e6a5!2sCipa%20Sport!5e0!3m2!1sen!2smk!4v1647865817414!5m2!1sen!2smk"
            width="600"
            height="450"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
