import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { PlusSmIcon } from "@heroicons/react/solid";
import { BreadCrumbs } from "../breadcrumbs/breadcrumbs";
import { TypeOfProductsToReturn } from "../../service/TypeOfProductsToReturn";
import ReactPaginate from "react-paginate";
import { Product } from "../../service/interfaces";
import { pathDict } from "../../service/constants";
import slugify from "slugify";

export const CategoryPage: React.FC = () => {
  const brandFilters = [
    { name: "Nike" },
    { name: "Jack Wolfskin" },
    { name: "New Balance" },
    { name: "Color Kids" },
  ];
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const { category, type, searchTerm } = useParams<string>();
  const [sort, setSort] = useState<string>("newest");
  const [products, setProducts] = useState<Product[]>([]);
  const [articles, setArticles] = useState(products);
  const [articlesPerPage, setArticlesPerPage] = useState(12);
  const [checkedBrands, setCheckedBrands] = useState(
    new Array(brandFilters.length).fill(false)
  );
  const [pageNumber, setPageNumber] = useState(0);

  let pagesVisited = pageNumber * articlesPerPage;

  useEffect(() => {
    changePage({ selected: 0 });
    let products: Product[] = TypeOfProductsToReturn.getProducts(
      category,
      type
    );
    (document.getElementById("sort") as HTMLSelectElement).value = "newest";

    setSort("newest");
    if (sort === "price_asc") {
      products.sort((a: Product, b: Product) =>
        a.Price - a.Price * (a.Discount / 100) >
        b.Price - b.Price * (b.Discount / 100)
          ? 1
          : -1
      );
    }
    if (sort === "newest") {
      products.sort((a: Product, b: Product) =>
        a.DateAdded < b.DateAdded ? 1 : -1
      );
    }

    setProducts(products);
    setArticles(products);
  }, [category, type]);

  useEffect(() => {
    const products: Product[] = articles.slice(
      pagesVisited,
      pagesVisited + articlesPerPage
    );
    setProducts(products);
  }, [articles, pagesVisited]);

  let pageCount = Math.ceil(articles.length / articlesPerPage);
  const changePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    let filterProducts: Product[] = TypeOfProductsToReturn.getProducts(
      category,
      type
    );
    let productsFilteredByBrands: Product[] = [];

    if (checkedBrands[0] === true) {
      productsFilteredByBrands = productsFilteredByBrands.concat(
        filterProducts.filter((f) => f.Brand === "Nike")
      );
    }
    if (checkedBrands[1] === true) {
      productsFilteredByBrands = productsFilteredByBrands.concat(
        filterProducts.filter((f) => f.Brand === "Jack Wolf")
      );
    }
    if (checkedBrands[2] === true) {
      productsFilteredByBrands = productsFilteredByBrands.concat(
        filterProducts.filter((f) => f.Brand === "New Balance")
      );
    }
    if (checkedBrands[3] === true) {
      productsFilteredByBrands = productsFilteredByBrands.concat(
        filterProducts.filter((f) => f.Brand === "Color Kids")
      );
    }

    filterProducts = productsFilteredByBrands;

    if (
      productsFilteredByBrands.length === 0 &&
      checkedBrands[0] === false &&
      checkedBrands[1] === false &&
      checkedBrands[2] === false &&
      checkedBrands[3] === false
    ) {
      filterProducts = TypeOfProductsToReturn.getProducts(category, type);
    }

    if (sort === "price_asc") {
      filterProducts.sort((a: Product, b: Product) =>
        a.Price - a.Price * (a.Discount / 100) >
        b.Price - b.Price * (b.Discount / 100)
          ? 1
          : -1
      );
    }

    if (sort === "price_desc") {
      filterProducts.sort((a: Product, b: Product) =>
        a.Price - a.Price * (a.Discount / 100) <
        b.Price - b.Price * (b.Discount / 100)
          ? 1
          : -1
      );
    }

    if (sort === "newest") {
      filterProducts.sort((a: Product, b: Product) =>
        a.DateAdded < b.DateAdded ? 1 : -1
      );
    }
    setArticles(filterProducts);
    const products: Product[] = articles.slice(
      pagesVisited,
      pagesVisited + articlesPerPage
    );
    setProducts(products);
    changePage({ selected: 0 });
  }, [sort, checkedBrands]);

  useEffect(() => {
    pagesVisited = pageNumber * articlesPerPage;
    pageCount = Math.ceil(articles.length / articlesPerPage);
    const products: Product[] = articles.slice(
      pagesVisited,
      pagesVisited + articlesPerPage
    );
    changePage({ selected: 0 });
    setProducts(products);
  }, [articlesPerPage]);

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedBrands.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedBrands(updatedCheckedState);
  };

  console.log(products);

  return (
    <div className="bg-white mt-10 ">
      <div>
        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 pt-5 pb-2 flex">
                  <button
                    type="button"
                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                  <div className="flow-root">
                    <Link
                      to="#"
                      className="-m-2 p-2 block font-medium text-gray-900"
                    >
                      Sign in
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link
                      to="#"
                      className="-m-2 p-2 block font-medium text-gray-900"
                    >
                      Create account
                    </Link>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </div>

      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
                <div className="px-4  items-center justify-between">
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 p-2 flex items-center justify-center text-gray-400 hover:text-gray-500"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid grid-cols-1 justify-center mt-5">
                    <h1 className="mb-4 text-xl font-medium">Брендови: </h1>
                    {brandFilters.map((filter, index) => (
                      <ul className="mb-1">
                        <li key={index}>
                          <div className="h-full ml-auto">
                            <input
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              name={filter.name}
                              value={filter.name}
                              checked={checkedBrands[index]}
                              onChange={() => handleOnChange(index)}
                              className="mx-auto"
                            />
                            <label
                              className="ml-2 font-medium mx-auto"
                              htmlFor={`custom-checkbox-${index}`}
                            >
                              {filter.name}
                            </label>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <div className="border-gray-200">
          <nav
            aria-label="Breadcrumb"
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          >
            {(() => {
              if (type) {
                return (
                  <BreadCrumbs
                    Pages={[
                      {
                        name: category !== undefined ? pathDict[category] : "",
                        href: "/category_page/" + category,
                        current: false,
                      },
                      {
                        name: type !== undefined ? pathDict[type] : "",
                        current: true,
                        href: "#",
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <BreadCrumbs
                    Pages={[
                      {
                        name: ("Пребарано: " + searchTerm) as string,
                        current: true,
                        href: "#",
                      },
                    ]}
                  />
                );
              }
            })()}
          </nav>
        </div>

        <main className="max-w-2xl mx-auto px-4 lg:max-w-7xl lg:px-8 border-t border-gray-200 mt-10 ">
          <div className="pt-12 pb-24 lg:grid lg:grid-cols-5 lg:gap-x-10 xl:grid-cols-5">
            <aside>
              <h2 className="sr-only">Filters</h2>

              <button
                type="button"
                className="inline-flex items-center lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="text-sm font-medium text-gray-700">
                  Филтри
                </span>
                <PlusSmIcon
                  className="flex-shrink-0 ml-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>

              <div className="hidden lg:block">
                <div>
                  <label
                    htmlFor="location"
                    className="block text-sm font-bold text-gray-700 mt-10 mb-5 "
                  >
                    Брендови:
                  </label>
                  {brandFilters.map((filter, index) => (
                    <ul className="text-left">
                      <li key={index}>
                        <div className="toppings-list-item">
                          <div className="left-section">
                            <input
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              name={filter.name}
                              value={filter.name}
                              checked={checkedBrands[index]}
                              onChange={() => handleOnChange(index)}
                            />
                            <label
                              className="ml-2 font-medium"
                              htmlFor={`custom-checkbox-${index}`}
                            >
                              {filter.name}
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </aside>
            <section
              aria-labelledby="product-heading"
              className="mt-6 lg:mt-0 lg:col-span-3 xl:col-span-4"
            >
              <h2 id="product-heading" className="sr-only">
                Products
              </h2>
              <div className="flex justify-between ">
                <div>
                  <select
                    id="sort"
                    className="mb-5 mt-1 block pl-3 pr-10 py-2 text-base  border-gray-300 border-2 focus:outline-none focus:ring-cipa-red-500 focus:border-cipa-red-500 sm:text-sm rounded-md justify-end ml-auto"
                    defaultValue="newest"
                    onChange={(e) => {
                      setSort(e.target.value.toString());
                    }}
                  >
                    <option selected value="newest">
                      Најново
                    </option>
                    <option value="price_asc">Цена : Растечка</option>
                    <option value="price_desc">Цена : Опаѓачка</option>
                  </select>
                </div>

                <div className="">
                  <select
                    id="numberOfArticlesPerPage"
                    className="mb-5 mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 border-2  focus:outline-none focus:ring-cipa-red-500 focus:border-cipa-red-500 sm:text-sm rounded-md justify-end ml-auto"
                    defaultValue="12"
                    onChange={(e) => {
                      setArticlesPerPage(parseInt(e.target.value));
                    }}
                  >
                    <option selected value="12">
                      12
                    </option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                  </select>
                </div>
              </div>
              {(() => {
                if (products.length === 0) {
                  return (
                    <div className="bg-cipa-red-700 text-white">
                      <h1>Не се пронајдени производи!</h1>
                    </div>
                  );
                }
              })()}

              <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4">
                {products.map((product: Product, idx: number) => (
                  <div
                    key={idx}
                    className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden "
                  >
                    {/* <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96 lg:w-56 lg:h-56"> */}
                    <div className=" bg-gray-200 group-hover:opacity-75 sm:aspect-none lg:h-auto lg:w-auto mx-auto relative">
                      <img
                        src={product.Images[0]}
                        className="w-full object-contain"
                      />

                      {product.Discount !== 0 ? (
                        <div className="absolute top-0 right-0 px-2 py-1 bg-cipa-red-500">
                          <span className="text-white">
                            -{product.Discount}%
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="flex-1 p-4 space-y-2 flex flex-col">
                      <h3 className="text-sm font-medium text-gray-900">
                        <Link
                          to={
                            "/product_page/" +
                            product.Category.split(" ")[0] +
                            "/" +
                            product.Category.split(" ")[1] +
                            "/" +
                            product.Product_Code
                          }
                        >
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          {product.Product_Name}
                        </Link>
                      </h3>
                      <div className="flex-1 flex flex-col justify-end">
                        {(() => {
                          if (product.Discount === 0) {
                            if (product.Price > 999) {
                              return (
                                <p className="text-base font-medium text-gray-900">
                                  {(product.Price / 1000).toFixed(3)} Ден
                                </p>
                              );
                            } else {
                              return (
                                <p className="text-base font-medium text-gray-900">
                                  {product.Price} Ден
                                </p>
                              );
                            }
                          } else {
                            if (product.Price > 999) {
                              return (
                                <div>
                                  <p className="text-base font-medium text-black line-through">
                                    {(product.Price / 1000).toFixed(3)} Ден
                                  </p>
                                  <p className="text-base font-medium text-cipa-red-500">
                                    {(
                                      Math.round(
                                        product.Price -
                                          product.Price *
                                            (product.Discount / 100)
                                      ) / 1000
                                    ).toFixed(3)}{" "}
                                    Ден
                                  </p>
                                </div>
                              );
                            } else {
                              return (
                                <p className="text-base font-medium text-gray-900">
                                  {product.Price} Ден
                                </p>
                              );
                            }
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-20 flex border-t border-gray-200 px-4 items-center justify-between sm:px-0 max-w-20">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  forcePage={pageNumber}
                />
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};
