import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { BreadCrumbs } from "../breadcrumbs/breadcrumbs";
import Alert from "./alert";
import { Order } from "../../service/interfaces";
import { useNavigate } from "react-router-dom";

interface Props {
  ShoppingCart: Order[];
  RemoveCartTerm: (id: string | undefined) => void;
  sendOrder: (
    name: string,
    lastName: string,
    email: string,
    phoneNumber:string,
    address: string,
    municipality: string,
    city: string,
    postalCode: string
  ) => void;
}

export const Checkout: React.FC<Props> = (props: Props) => {
  let navigate = useNavigate();

  const [alert, setAlert] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [city, setCity] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [agree, setAgree] = useState(false);
  const [phoneNumber,setPhoneNumber] = useState("");

  const pages = [
    {
      name: "Корисничка кошничка",
      href: "/shopping_cart",
      current: false,
    },
    { name: "Потврди", href: "#", current: true },
  ];

  const products = props.ShoppingCart;
  let total = 0;
  props.ShoppingCart.map((p) => {
    total = total + p.Price * p.Quantity;
  });

  function checkInfo() {
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      address.length > 0 &&
      phoneNumber.length > 0 &&
      municipality.length > 0 &&
      city.length > 0 &&
      postalcode.length > 0 &&
      agree
    ) {
      props.sendOrder(
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        municipality,
        city,
        postalcode
      );
      navigate("/order_detail");
    } else {
      setAlert(true);
    }
  }

  function handleNameChange(e: any) {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    }
    if (e.target.name === "lastName") {
      setLastName(e.target.value);
    }
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "address") {
      setAddress(e.target.value);
    }
    if (e.target.name === "municipality") {
      setMunicipality(e.target.value);
    }
    if (e.target.name === "city") {
      setCity(e.target.value);
    }
    if (e.target.name === "postalcode") {
      setPostalcode(e.target.value);
    }
    if (e.target.name === "agree") {
      setAgree(e.target.checked);
    }
    if(e.target.name === "phoneNum"){
      setPhoneNumber(e.target.value);
    }
  }
  return (
    <>
      <main className="lg:min-h-full lg:overflow-hidden lg:flex lg:flex-row-reverse mb-10 mx-auto py-10 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-5">
        <div className="lg:hidden sm:block mb-5">
          <BreadCrumbs Pages={pages} />
        </div>

        <h1 className="sr-only">Checkout</h1>

        {/* Mobile order summary */}
        <section
          aria-labelledby="order-heading"
          className="bg-cipa-blue-500 px-4 py-6 sm:px-6 lg:hidden"
        >
          <Disclosure as="div" className="max-w-lg mx-auto">
            {({ open }) => (
              <>
                <div className="flex items-center justify-between">
                  <h2
                    id="order-heading"
                    className="text-lg font-medium text-gray-400"
                  >
                    Ваша нарачка
                  </h2>
                  <Disclosure.Button className="font-medium text-gray-400">
                    {open ? (
                      <span>Сокри нарачка</span>
                    ) : (
                      <span className="text-cipa-red-600">Покажи нарачка</span>
                    )}
                  </Disclosure.Button>
                </div>

                <Disclosure.Panel>
                  <ul
                    role="list"
                    className="divide-y divide-gray-200 border-b border-gray-200"
                  >
                    {products.map((product) => (
                      <li
                        key={product.Product_Code}
                        className="flex py-6 space-x-6"
                      >
                        <img
                          src={product.Images[0]}
                          className="flex-none w-40 h-40 object-center object-cover bg-gray-200 rounded-md"
                        />
                        <div className="flex flex-col justify-between space-y-4 text-justify">
                          <div className="text-sm font-medium space-y-1">
                            <h3 className="text-gray-400">
                              Име: <br />
                              {product.Product_Name}
                            </h3>
                            <p className="text-white">
                              Цена:
                              {(() => {
                                if (product.Discount === 0) {
                                  if (product.Price > 999) {
                                    return (
                                      <p className="text-base font-medium text-left text-white">
                                        Цена: {(product.Price / 1000).toFixed(3)} Ден
                                      </p>
                                    );
                                  } else {
                                    return (
                                      <p className="text-base font-medium text-left text-white">
                                        Цена: {product.Price} Ден
                                      </p>
                                    );
                                  }
                                } else {
                                  if (product.Price > 999) {
                                    return (
                                      <div className="text-left">
                                        <p className="text-base font-medium text-cipa-red-500">
                                          - {product.Discount} %
                                        </p>
                                        <p className="text-base font-medium text-cipa-red-500">
                                        Цена: {(product.Price / 1000).toFixed(3)}{" "}
                                          Ден
                                        </p>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="text-left">
                                        <p className="text-base font-medium text-cipa-red-500">
                                          - {product.Discount} %
                                        </p>
                                        <p className="text-base font-medium text-cipa-red-500">
                                        Цена: {product.Price} Ден
                                        </p>
                                      </div>
                                    );
                                  }
                                }
                              })()}
                            </p>
                            {/* <p className="text-gray-400">Боја: <br/>{product.color}</p> */}
                            <p className="text-gray-400">
                              Големина: <br />
                              {product.Size}
                            </p>
                            <p className="text-gray-400">
                              Количина: <br />
                              {product.Quantity}
                            </p>
                            <br />
                            <button
                              type="button"
                              className="text-sm font-medium bg-red-700 text-white hover:bg-cipa-red-600 rounded-md p-2"
                              onClick={() =>
                                props.RemoveCartTerm(product.Product_Code)
                              }
                            >
                              Отстрани
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Disclosure.Panel>

                <p className="flex items-center justify-between text-sm font-medium text-white pt-6 mt-6">
                  <span className="text-base">Вкупно</span>
                  <span className="text-base">
                    {(total / 1000).toFixed(3)} Ден
                  </span>
                </p>
              </>
            )}
          </Disclosure>
        </section>

        {/* Order summary */}
        <section
          aria-labelledby="summary-heading"
          className="hidden bg-cipa-blue-500 w-full max-w-md flex-col lg:flex"
        >
          <h2 id="summary-heading" className="sr-only">
            Order summary
          </h2>

          <ul
            role="list"
            className="flex-auto overflow-y-auto divide-y divide-gray-200 px-6"
          >
            {products.map((product) => (
              <li key={product.Product_Code} className="flex py-6 space-x-6">
                <img
                  src={product.Images[0]}
                  className="flex-none w-40 h-40 object-center object-cover bg-gray-200 rounded-md"
                />
                <div className="flex flex-col justify-between space-y-4 text-justify">
                  <div className="text-sm font-medium space-y-1">
                    <h3 className="text-gray-400">
                      Име: {product.Product_Name}
                    </h3>
                    <p className="text-white">
                      {(() => {
                        if (product.Discount === 0) {
                          if (product.Price > 999) {
                            return (
                              <p className="text-base font-medium text-left text-white">
                                Цена: {(product.Price / 1000).toFixed(3)} Ден
                              </p>
                            );
                          } else {
                            return (
                              <p className="text-base font-medium text-left text-white">
                                Цена: {product.Price} Ден
                              </p>
                            );
                          }
                        } else {
                          if (product.Price > 999) {
                            return (
                              <div className="text-left">
                                <p className="text-base ml-12 font-medium text-cipa-red-500">
                                  - {product.Discount} %
                                </p>
                                <p className="text-base font-medium text-cipa-red-500">
                                Цена: {(product.Price / 1000).toFixed(3)} Ден
                                </p>
                              </div>
                            );
                          } else {
                            return (
                              <div className="text-left">
                                <p className="text-base ml-12 font-medium text-cipa-red-500">
                                  - {product.Discount} %
                                </p>
                                <p className="text-base font-medium text-cipa-red-500">
                                Цена: {product.Price} Ден
                                </p>
                              </div>
                            );
                          }
                        }
                      })()}
                    </p>
                    {/* <p className="text-gray-400">Боја: {product.color}</p> */}
                    <p className="text-gray-400">Големина: {product.Size}</p>
                    <p className="text-gray-400">
                      Количина: {product.Quantity}
                    </p>
                    <br />
                    <button
                      type="button"
                      className="text-sm font-medium bg-red-700 text-white hover:bg-cipa-red-600 rounded-md p-2"
                      onClick={() => props.RemoveCartTerm(product.Product_Code)}
                    >
                      Отстрани
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="sticky bottom-0 flex-none bg-cipa-blue-500">
            <dl className="text-sm font-bold text-gray-500 mt-10 space-y-6">
              <div className="flex items-center justify-between border-t border-gray-200 text-white pt-6">
                <dt className="text-base p-5">Вкупно</dt>
                <dd className="text-base mr-5">
                  {(total / 1000).toFixed(3)} Ден
                </dd>
              </div>
            </dl>
          </div>
        </section>

        {/* Checkout form */}
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
        >
          <div className="max-w-lg mx-auto">
            <div className="hidden pt-10 pb-16 lg:flex">
              <div className="sm:hidden lg:block">
                <BreadCrumbs Pages={pages} />
              </div>
            </div>

            <form className="mt-6">
              <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                <div className="col-span-full">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Име
                  </label>
                  <div className="mt-1">
                    <input
                      id="firstName"
                      name="firstName"
                      className="block w-full h-8 border-2 border-cipa-blue-700 rounded-md shadow-sm  sm:text-sm"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="name-on-card"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Презиме
                  </label>
                  <div className="mt-1">
                    <input
                      id="lastName"
                      name="lastName"
                      autoComplete="cc-name"
                      className="block  h-8  w-full border-2 border-cipa-blue-700 rounded-md shadow-s sm:text-sm"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="card-number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-Mail
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      autoComplete="cc-number"
                      className="block w-full h-8  border-2 border-cipa-blue-700 rounded-md shadow-sm sm:text-sm"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Телефонски број
                  </label>
                  <div className="mt-1">
                    <input
                      id="phoneNum"
                      name="phoneNum"
                      className="block w-full h-8  border-2 border-cipa-blue-700 rounded-md shadow-sm  sm:text-sm"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Адреса
                  </label>
                  <div className="mt-1">
                    <input
                      id="address"
                      name="address"
                      autoComplete="street-address"
                      className="block w-full h-8  border-2 border-cipa-blue-700 rounded-md shadow-sm  sm:text-sm"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>

                <div className="col-span-full sm:col-span-4">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Општина
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="municipality"
                      name="municipality"
                      autoComplete="address-level2"
                      className="block w-full h-8  border-2 border-cipa-blue-700 rounded-md shadow-sm  sm:text-sm"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>

                <div className="col-span-full sm:col-span-4">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Град
                  </label>
                  <div className="mt-1">
                    <input
                      id="city"
                      autoComplete="address-level1"
                      className="block w-full h-8  border-2 border-cipa-blue-700 rounded-md shadow-sm sm:text-sm"
                      name="city"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>

                <div className="col-span-full sm:col-span-4">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Поштенски код
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="postalcode"
                      name="postalcode"
                      autoComplete="postal-code"
                      className="block w-full h-8  border-2 border-cipa-blue-700 rounded-md shadow-sm sm:text-sm"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6 flex space-x-2">
                <div className="flex items-center h-5">
                  <input
                    id="agree"
                    type="checkbox"
                    name="agree"
                    className="h-4 w-4 border-gray-300 rounded text-cipa-blue-700 focus:ring-cipa-blue-500"
                    onChange={handleNameChange}
                  />
                </div>
                <label
                  htmlFor="same-as-shipping"
                  className="text-sm font-medium text-gray-900"
                >
                  Ќе бидете контактирани за достапноста на прозиводите и
                  потврдување на нарачката
                </label>
              </div>

              {alert ? <Alert /> : ""}
              <button
                type="button"
                className="w-full mt-6 bg-cipa-blue-700 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-cipa-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cipa-blue-400"
                onClick={checkInfo}
              >
                Нарачај
              </button>
            </form>
          </div>
        </section>
      </main>
    </>
  );
};
