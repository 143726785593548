const menFootwear = require("../Jsons/Men_Footwear");
const menApparel = require("../Jsons/Men_Apparel");
const menEquipment = require("../Jsons/Men_Equipment");

const womenFootwear = require("../Jsons/Women_Footwear");
const womenApparel = require("../Jsons/Women_Apparel");
const womenEquipment = require("../Jsons/Women_Equipment");

const kidsFootwear = require("../Jsons/Kids_Footwear");
const kidsApparel = require("../Jsons/Kids_Apparel");
const kidsEquipment = require("../Jsons/Kids_Equipment");

const littleKidsFootwear = require("../Jsons/Little_Kids_Footwear");
const littleKidsApparel = require("../Jsons/Little_Kids_Apparel");
const littleKidsEquipment = require("../Jsons/Little_Kids_Equipment");

const infantsFootwear = require("../Jsons/Infants_Footwear");
const infantsApparel = require("../Jsons/Infants_Apparel");
const infantsEquipment = require("../Jsons/Infants_Equipment");

export const ProductsJson = {
  getAllProducts: () => {
    let allProducts = ProductsJson.getMenFootwear();
    allProducts = allProducts.concat(ProductsJson.getMenApparel());
    allProducts = allProducts.concat(ProductsJson.getMenEquipment());

    allProducts = allProducts.concat(ProductsJson.getWomenFootwear());
    allProducts = allProducts.concat(ProductsJson.getWomenApparel());
    allProducts = allProducts.concat(ProductsJson.getWomenEquipment());

    allProducts = allProducts.concat(ProductsJson.getKidsFootwear());
    allProducts = allProducts.concat(ProductsJson.getKidsApparel());
    allProducts = allProducts.concat(ProductsJson.getKidsEquipment());

    allProducts = allProducts.concat(ProductsJson.getLittleKidsFootwear());
    allProducts = allProducts.concat(ProductsJson.getLittleKidsApparel());
    allProducts = allProducts.concat(ProductsJson.getLittleKidsEquipment());

    allProducts = allProducts.concat(ProductsJson.getInfantsFootwear());
    allProducts = allProducts.concat(ProductsJson.getInfantsApparel());
    allProducts = allProducts.concat(ProductsJson.getInfantsEquipment());

    return allProducts;
  },
  getMenFootwear: () => {
    return menFootwear;
  },
  getMenApparel: () => {
    return menApparel;
  },
  getMenEquipment: () => {
    return menEquipment;
  },

  getWomenFootwear: () => {
    return womenFootwear;
  },
  getWomenApparel: () => {
    return womenApparel;
  },
  getWomenEquipment: () => {
    return womenEquipment;
  },

  getKidsFootwear: () => {
    return kidsFootwear;
  },
  getKidsApparel: () => {
    return kidsApparel;
  },
  getKidsEquipment: () => {
    return kidsEquipment;
  },

  getLittleKidsFootwear: () => {
    return littleKidsFootwear;
  },
  getLittleKidsApparel: () => {
    return littleKidsApparel;
  },
  getLittleKidsEquipment: () => {
    return littleKidsEquipment;
  },

  getInfantsFootwear: () => {
    return infantsFootwear;
  },
  getInfantsApparel: () => {
    return infantsApparel;
  },
  getInfantsEquipment: () => {
    return infantsEquipment;
  },
};
