import { Order } from "../service/interfaces";
export const LocalStorageService = {
  saveToLocalStorage: (KEY: string, payload: Order[]) => {
    localStorage.setItem(KEY, JSON.stringify(payload));
  },

  fetchFromLocalStorage: (KEY: string) => {
    const jsonStr = localStorage.getItem(KEY);
    if (!jsonStr) return null;
    return JSON.parse(jsonStr);
  },
  clearAll: () => {
    localStorage.clear();
  },
};
