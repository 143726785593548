import { Link } from "react-router-dom";
import { footerLinks as navigation } from "../../service/constants";

export const Footer: React.FC = () => {
  return (
    <footer className="bg-cipa-blue-500 ">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item, idx) => (
            <div key={idx} className="px-5 py-2">
              <Link
                to={item.href}
                className="text-base text-gray-400 hover:text-gray-200 "
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map((item, idx) => (
            <a
              key={idx}
              href={item.href}
              className="text-gray-400 hover:text-gray-200"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-8 w-8" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; 2022 Cipa Sport. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
