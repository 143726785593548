import React from "react";
import { Link } from "react-router-dom";
import { categoriesCallouts as callouts } from "../../service/constants";

export const Categories: React.FC = () => {
  return (
    <div className="bg-gray-100 rounded-lg mb-10 backdrop-filter backdrop-blur bg-opacity-75 text-gray-800 pt-10 pb-20 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-5 ">
        <div className="max-w-2xl mx-auto py-5 sm:py-5 lg:py-5 lg:max-w-none">
          <div className="mt-6 space-y-12 lg:space-y-0 sm:grid-cols-3 lg:grid lg:grid-cols-3 lg:gap-x-6 ">
            {callouts.map((callout, idx) => (
              <div key={idx} className="group relative">
                <Link to={callout.href}>
                  <div className="relative w-full h-full  bg-opacity-0  rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className=" object-center object-contain h-full w-full"
                    />
                  </div>
                  <p className="font-semibold text-xl mt-10">
                    {callout.description}
                  </p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
