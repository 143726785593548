//NIKE
const menFootwearNike = "/CipaSportImages/AA7396-001_3.jpg";
const menApparelNike = "/CipaSportImages/DD6795-326_2.jpg";
const womenFootwearNike = "/CipaSportImages/812655-861_2.jpg";
const womenApparelNike = "/CipaSportImages/CZ8598-010_1.jpg";

const firstTypeNike = "/CipaSportImages/CD4165-105_3.jpg";
const secondTypeNike = "/CipaSportImages/AR3762-600_1.jpg";
const thirdTypeNike = "/CipaSportImages/BQ9647-601_3.jpg";

const firstBannerNike = "/CipaSportImages/airmax.jpg";
const secondBannerNike = "/CipaSportImages/lina.jpg";
const thirdBannerNike = "/CipaSportImages/jordan.jpg";

const nikeSlogan = "JUST DO IT";
const nikeWebsite = "https://www.nike.com/gb/";
const nikeBtnText = "www.Nike.com";

//JACK_WOLFSKIN

const menFootwearJackWolf = "/CipaSportImages/4035461-6156_1.jpg";
const menApparelJackWolf = "/CipaSportImages/1206421-5066_1.jpg";
const womenFootwearJackWolf = "/CipaSportImages/4044151-6069_2.jpg";
const womenApparelJackWolf = "/CipaSportImages/1113701-1910_3.jpg";

const firstTypeJackWolf = "/CipaSportImages/4039211-6000_2.jpg";
const secondTypeJackWolf = "/CipaSportImages/1111711-1249_2.jpg";
const thirdTypeJackWolf = "/CipaSportImages/1907841-1910_1.jpg";

const firstBannerJackWolf = "/CipaSportImages/jackwolfbanner_3.jpg";
const secondBannerJackWolf = "/CipaSportImages/jackwolfbanner_1.jpg";
const thirdBannerJackWolf = "/CipaSportImages/jackwolfbanner_2.jpg";

const jackWolfskinSlogan = "SUSTAINABLE OUTDOOR CLOTHING, GEAR, & FOOTWEAR";
const jackWolfskinWebsite = "https://www.jack-wolfskin.com/";
const jackWolfskinBtnText = "www.Jack_Wolfskin.com";

//COLOR_KIDS

const menFootwearColorKids = "/CipaSportImages/760034-6554_2.jpg";
const menApparelColorKids = "/CipaSportImages/740346-7700_1.jpg";
const womenFootwearColorKids = "/CipaSportImages/740439-2250_1.jpg";
const womenApparelColorKids = "/CipaSportImages/740304-7700_3.jpg";

const firstTypeColorKids = "/CipaSportImages/760034-2254_1.jpg";
const secondTypeColorKids = "/CipaSportImages/740308-5590_1.jpg";
const thirdTypeColorKids = "/CipaSportImages/740441-7850_1.jpg";

const firstBannerColorKids = "/CipaSportImages/colorkids_3.jpg";
const secondBannerColorKids = "/CipaSportImages/colorkids_1.jpg";
const thirdBannerColorKids = "/CipaSportImages/colorkids_2.jpg";

const colorKidsSlogan = "";
const colorKidsWebsite = "https://www.brands4kids-shop.com/color-kids/";
const colorKidsBtnText = "www.Color_Kids.com";

//Vans

const menFootwearVans = "/CipaSportImages/vans_men_footwear.jpg";
const menApparelVans = "/CipaSportImages/vans_men_apparel.jpg";
const womenFootwearVans = "/CipaSportImages/vans_women_footwear.jpg";
const womenApparelVans = "/CipaSportImages/vans_women_apparel.jpg";

const firstTypeVans = "/CipaSportImages/vans_kids_footwear.jpg";
const secondTypeVans = "/CipaSportImages/vans_kids_apparel.jpg";
const thirdTypeVans = "/CipaSportImages/vans_kids_equipment.jpg";

const firstBannerVans = "/CipaSportImages/vans_2.jpg";
const secondBannerVans = "/CipaSportImages/vans_6.jpg";
const thirdBannerVans = "/CipaSportImages/vans_7.jpg";

const vansSlogan = "OFF THE WALL";
const vansWebsite = "https://www.vans.eu/";
const vansBtnText = "www.Vans.com";

//New Balance

const menFootwearNewBalance = "/CipaSportImages/newbalance_men_footwear.jpg";
const menApparelNewBalance = "/CipaSportImages/newbalance_men_apparel.jpg";
const womenFootwearNewBalance =
  "/CipaSportImages/newbalance_women_footwear.jpg";
const womenApparelNewBalance = "/CipaSportImages/newbalance_women_apparel.jpg";

const firstTypeNewBalance = "/CipaSportImages/newbalance_kids_apparel.jpg";
const secondTypeNewBalance = "/CipaSportImages/newbalance_kids_footwear.jpg";
const thirdTypeNewBalance = "/CipaSportImages/newbalance_infants_apparel.jpg";

const firstBannerNewBalance = "/CipaSportImages/newbalance_2.jpg";
const secondBannerNewBalance = "/CipaSportImages/newbalance_3.jpg";
const thirdBannerNewBalance = "/CipaSportImages/newbalance_5.jpg";

const newBalanceSlogan = "MOVE FOR YOU";
const newBalanceWebsite = "https://www.newbalance.com/";
const newBalanceBtnText = "www.New_Balance.com";

//Helly hansen

const menFootwearHellyHansen = "/CipaSportImages/10874-728_1.jpg";
const menApparelHellyHansen = "/CipaSportImages/hellyhansen_men_apparel.jpg";
const womenFootwearHellyHansen =
  "/CipaSportImages/hellyhansen_women_footwear.jpg";
const womenApparelHellyHansen =
  "/CipaSportImages/hellyhansen_women_apparel.jpg";
const firstTypeHelleyHansen = "/CipaSportImages/hellyhansen_kids_footwear.jpg";
const secondTypeHelleyHansen = "/CipaSportImages/hellyhansen_kids_apparel.jpg";
const thirdTypeHelleyHansen = "/CipaSportImages/hellyhansen_kids_equipment.jpg";

const firstBannerHelleyHansen = "/CipaSportImages/hellyhansen_2.jpg";
const secondBannerHelleyHansen = "/CipaSportImages/hellyhansen_1.jpg";
const thirdBannerHelleyHansen = "/CipaSportImages/hellyhansen_3.jpg";

const helleyHansenSlogan = "LIFA INFINITY™";
const helleyHansenWebsite = "https://www.hellyhansen.com/";
const hellyHansenBtnText = "www.Helly_Hansen.com";

//Lacoste

const menFootwearLacoste = "/CipaSportImages/lacoste_men_footwear.jpg";
const menApparelLacoste = "/CipaSportImages/lacoste_men_apparel.jpg";
const womenFootwearLacoste = "/CipaSportImages/lacoste_women_footwear.jpg";
const womenApparelLacoste = "/CipaSportImages/lacoste_women_apparel.jpg";

const firstTypeLacoste = "/CipaSportImages/lacoste_kids_footwear.jpg";
const secondTypeLacoste = "/CipaSportImages/lacoste_kids_apparel.jpg";
const thirdTypeLacoste = "/CipaSportImages/lacoste_kids_equipment.jpg";

const firstBannerLacoste = "/CipaSportImages/lacoste_1.jpg";
const secondBannerLacoste = "/CipaSportImages/lacoste_2.jpg";
const thirdBannerLacoste = "/CipaSportImages/lacoste_4.jpg";

const lacosteSlogan = "LIFE IS A BEAUTIFUL SPORT";
const lacosteWebsite = "https://global.lacoste.com/en/homepage";
const lacosteBtnText = "www.Lacoste.com";

let categories: any[];
let collections: any[];

let firstBanner: string;
let secondBanner: string;
let thirdBanner: string;
let slogan: string;
let websiteLink: string;
let btnText: string;

export const showcase = {
  getBrand(brand: string | undefined) {
    if (brand === "nike") {
      categories = [
        {
          name: "Обувки за мажи",
          href: "/category_page/Mazi/Nike Obuvki",
          imageSrc: menFootwearNike,
        },
        {
          name: "Облека за мажи",
          href: "/category_page/Mazi/Nike Obleka",
          imageSrc: menApparelNike,
        },
        {
          name: "Обувки за жени",
          href: "/category_page/Zeni/Nike Obuvki",
          imageSrc: womenFootwearNike,
        },
        {
          name: "Облека за жени",
          href: "/category_page/Zeni/Nike Obleka",
          imageSrc: womenApparelNike,
        },
      ];

      collections = [
        {
          name: "Air_Max",
          href: "/category_page/Mazi/Air_Max",
          imageSrc: firstTypeNike,
          imageAlt:
            "Brown leather key ring with brass metal loops and rivets on wood table.",
          description:
            "Keep your phone, keys, and wallet together, so you can lose everything at once.",
        },
        {
          name: "Jordan",
          href: "/category_page/Mazi/Jordan",
          imageSrc: secondTypeNike,
          imageAlt:
            "Natural leather mouse pad on white desk next to porcelain mug and keyboard.",
          description:
            "The rest of the house will still be a mess, but your desk will look great.",
        },
        {
          name: "LINA",
          href: "/category_page/Zeni/Lina",
          imageSrc: thirdTypeNike,
          imageAlt:
            "Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.",
          description:
            "Be more productive than enterprise project managers with a single piece of paper.",
        },
      ];

      firstBanner = firstBannerNike;
      secondBanner = secondBannerNike;
      thirdBanner = thirdBannerNike;
      slogan = nikeSlogan;
      websiteLink = nikeWebsite;
      btnText = nikeBtnText;
      return {
        categories,
        collections,
        firstBanner,
        secondBanner,
        thirdBanner,
        slogan,
        websiteLink,
        btnText,
      };
    }
    if (brand === "jack_wolf") {
      categories = [
        {
          name: "Обувки за мажи",
          href: "/category_page/Mazi/Jack Wolfskin Obuvki",
          imageSrc: menFootwearJackWolf,
        },
        {
          name: "Облека за мажи",
          href: "/category_page/Mazi/Jack Wolfskin Oблека",
          imageSrc: menApparelJackWolf,
        },
        {
          name: "Обувки за жени",
          href: "/category_page/Zeni/Jack Wolfskin Obuvki",
          imageSrc: womenFootwearJackWolf,
        },
        {
          name: "Облека за жени",
          href: "/category_page/Zeni/Jack Wolfskin Oблека",
          imageSrc: womenApparelJackWolf,
        },
      ];
      collections = [
        {
          name: "Обувки",
          href: "/category_page/Сите/Jack Wolfskin Obuvki",
          imageSrc: firstTypeJackWolf,
          imageAlt:
            "Brown leather key ring with brass metal loops and rivets on wood table.",
          description:
            "Keep your phone, keys, and wallet together, so you can lose everything at once.",
        },
        {
          name: "Јакни",
          href: "/category_page/Сите/Jack Wolfskin Jakni",
          imageSrc: secondTypeJackWolf,
          imageAlt:
            "Natural leather mouse pad on white desk next to porcelain mug and keyboard.",
          description:
            "The rest of the house will still be a mess, but your desk will look great.",
        },
        {
          name: "Опрема",
          href: "/category_page/Сите/Jack Wolfskin Oprema",
          imageSrc: thirdTypeJackWolf,
          imageAlt:
            "Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.",
          description:
            "Be more productive than enterprise project managers with a single piece of paper.",
        },
      ];

      firstBanner = firstBannerJackWolf;
      secondBanner = secondBannerJackWolf;
      thirdBanner = thirdBannerJackWolf;
      slogan = jackWolfskinSlogan;
      websiteLink = jackWolfskinWebsite;
      btnText = jackWolfskinBtnText;

      return {
        categories,
        collections,
        firstBanner,
        secondBanner,
        thirdBanner,
        slogan,
        websiteLink,
        btnText,
      };
    }
    if (brand === "color_kids") {
      categories = [
        {
          name: "Обувки за Мали Деца",
          href: "/category_page/Mali_Deca/Color Kids Obuvki",
          imageSrc: menFootwearColorKids,
        },
        {
          name: "Облека за Мали Деца",
          href: "/category_page/Mali_Deca/Color Kids Obleka",
          imageSrc: menApparelColorKids,
        },
        {
          name: "Oпрема за бебиња",
          href: "/category_page/Bebinja/Color Kids Oprema",
          imageSrc: womenFootwearColorKids,
        },
        {
          name: "Облека за бебиња",
          href: "/category_page/Bebinja/Color Kids Obleka",
          imageSrc: womenApparelColorKids,
        },
      ];
      collections = [
        {
          name: "Обувки",
          href: "/category_page/Сите/Color Kids Obuvki",
          imageSrc: firstTypeColorKids,
          imageAlt:
            "Brown leather key ring with brass metal loops and rivets on wood table.",
          description:
            "Keep your phone, keys, and wallet together, so you can lose everything at once.",
        },
        {
          name: "Oблека",
          href: "/category_page/Сите/Color Kids Obleka",
          imageSrc: secondTypeColorKids,
          imageAlt:
            "Natural leather mouse pad on white desk next to porcelain mug and keyboard.",
          description:
            "The rest of the house will still be a mess, but your desk will look great.",
        },
        {
          name: "Опрема",
          href: "/category_page/Сите/Color Kids Oprema",
          imageSrc: thirdTypeColorKids,
          imageAlt:
            "Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.",
          description:
            "Be more productive than enterprise project managers with a single piece of paper.",
        },
      ];

      firstBanner = firstBannerColorKids;
      secondBanner = secondBannerColorKids;
      thirdBanner = thirdBannerColorKids;
      slogan = colorKidsSlogan;
      websiteLink = colorKidsWebsite;
      btnText = colorKidsBtnText;
      return {
        categories,
        collections,
        firstBanner,
        secondBanner,
        thirdBanner,
        slogan,
        websiteLink,
        btnText,
      };
    }

    if (brand === "vans") {
      categories = [
        {
          name: "Обувки за мажи ",
          href: "/category_page/Mazi/Vans Obuvki",
          imageSrc: menFootwearVans,
        },
        {
          name: "Облека за мажи",
          href: "/category_page/Mazi/Vans Obleka",
          imageSrc: menApparelVans,
        },
        {
          name: "Обувки за жени",
          href: "/category_page/Zeni/Vans Obuvki",
          imageSrc: womenFootwearVans,
        },
        {
          name: "Облека за жени",
          href: "/category_page/Zeni/Vans Obleka",
          imageSrc: womenApparelVans,
        },
      ];
      collections = [
        {
          name: "Обувки за деца",
          href: "/category_page/Deca/Vans Obuvki",
          imageSrc: firstTypeVans,
          imageAlt:
            "Brown leather key ring with brass metal loops and rivets on wood table.",
          description:
            "Keep your phone, keys, and wallet together, so you can lose everything at once.",
        },
        {
          name: "Облека за деца",
          href: "/category_page/Deca/Vans Obleka",
          imageSrc: secondTypeVans,
          imageAlt:
            "Natural leather mouse pad on white desk next to porcelain mug and keyboard.",
          description:
            "The rest of the house will still be a mess, but your desk will look great.",
        },
        {
          name: "Опрема за деца",
          href: "/category_page/Deca/Vans Oprema",
          imageSrc: thirdTypeVans,
          imageAlt:
            "Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.",
          description:
            "Be more productive than enterprise project managers with a single piece of paper.",
        },
      ];

      firstBanner = firstBannerVans;
      secondBanner = secondBannerVans;
      thirdBanner = thirdBannerVans;
      slogan = vansSlogan;
      websiteLink = vansWebsite;
      btnText = vansBtnText;
      return {
        categories,
        collections,
        firstBanner,
        secondBanner,
        thirdBanner,
        slogan,
        websiteLink,
        btnText,
      };
    }

    if (brand === "new_balance") {
      categories = [
        {
          name: "Обувки за мажи",
          href: "/category_page/Mazi/New Balance Obuvki",
          imageSrc: menFootwearNewBalance,
        },
        {
          name: "Облека за мажи",
          href: "/category_page/Mazi/New Balance Obleka",
          imageSrc: menApparelNewBalance,
        },
        {
          name: "Обувки за жени",
          href: "/category_page/Zeni/New Balance Obuvki",
          imageSrc: womenFootwearNewBalance,
        },
        {
          name: "Облека за жени",
          href: "/category_page/Zeni/New Balance Obleka",
          imageSrc: womenApparelNewBalance,
        },
      ];
      collections = [
        {
          name: "Облека за деца",
          href: "/category_page/Deca/New Balance Obleka",
          imageSrc: firstTypeNewBalance,
          imageAlt:
            "Brown leather key ring with brass metal loops and rivets on wood table.",
          description:
            "Keep your phone, keys, and wallet together, so you can lose everything at once.",
        },
        {
          name: "Обувки за деца",
          href: "/category_page/Deca/New Balance Obuvki",
          imageSrc: secondTypeNewBalance,
          imageAlt:
            "Natural leather mouse pad on white desk next to porcelain mug and keyboard.",
          description:
            "The rest of the house will still be a mess, but your desk will look great.",
        },
        {
          name: "Облека за бебиња",
          href: "/category_page/Bebinja/New Balance Obleka",
          imageSrc: thirdTypeNewBalance,
          imageAlt:
            "Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.",
          description:
            "Be more productive than enterprise project managers with a single piece of paper.",
        },
      ];

      firstBanner = firstBannerNewBalance;
      secondBanner = secondBannerNewBalance;
      thirdBanner = thirdBannerNewBalance;
      slogan = newBalanceSlogan;
      websiteLink = newBalanceWebsite;
      btnText = newBalanceBtnText;
      return {
        categories,
        collections,
        firstBanner,
        secondBanner,
        thirdBanner,
        slogan,
        websiteLink,
        btnText,
      };
    }

    if (brand === "helley_hansen") {
      categories = [
        {
          name: "Обувки за мажи",
          href: "/category_page/Mazi/Helly Hansen Obuvki",
          imageSrc: menFootwearHellyHansen,
        },
        {
          name: "Облека за мажи",
          href: "/category_page/Mazi/Helly Hansen Obleka",
          imageSrc: menApparelHellyHansen,
        },
        {
          name: "Обувки за жени",
          href: "/category_page/Zeni/Helly Hansen Obuvki",
          imageSrc: womenFootwearHellyHansen,
        },
        {
          name: "Облека за жени",
          href: "/category_page/Zeni/Helly Hansen Obleka",
          imageSrc: womenApparelHellyHansen,
        },
      ];
      collections = [
        {
          name: "Обувки",
          href: "/category_page/Сите/Helly Hansen Obuvki",
          imageSrc: firstTypeHelleyHansen,
          imageAlt:
            "Brown leather key ring with brass metal loops and rivets on wood table.",
          description:
            "Keep your phone, keys, and wallet together, so you can lose everything at once.",
        },
        {
          name: "Јакни",
          href: "/category_page/Сите/Helly Hansen Obleka",
          imageSrc: secondTypeHelleyHansen,
          imageAlt:
            "Natural leather mouse pad on white desk next to porcelain mug and keyboard.",
          description:
            "The rest of the house will still be a mess, but your desk will look great.",
        },
        {
          name: "Опрема",
          href: "/category_page/Сите/Helly Hansen Oprema",
          imageSrc: thirdTypeHelleyHansen,
          imageAlt:
            "Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.",
          description:
            "Be more productive than enterprise project managers with a single piece of paper.",
        },
      ];

      firstBanner = firstBannerHelleyHansen;
      secondBanner = secondBannerHelleyHansen;
      thirdBanner = thirdBannerHelleyHansen;
      slogan = helleyHansenSlogan;
      websiteLink = helleyHansenWebsite;
      btnText = hellyHansenBtnText;
      return {
        categories,
        collections,
        firstBanner,
        secondBanner,
        thirdBanner,
        slogan,
        websiteLink,
        btnText,
      };
    }

    if (brand === "lacoste") {
      categories = [
        {
          name: "Обувки за мажи",
          href: "/category_page/Mazi/Lacoste Obuvki",
          imageSrc: menFootwearLacoste,
        },
        {
          name: "Облека за мажи",
          href: "/category_page/Mazi/Lacoste Obleka",
          imageSrc: menApparelLacoste,
        },
        {
          name: "Обувки за жени",
          href: "/category_page/Zeni/Lacoste Obuvki",
          imageSrc: womenFootwearLacoste,
        },
        {
          name: "Облека за жени",
          href: "/category_page/Zeni/Lacoste Obleka",
          imageSrc: womenApparelLacoste,
        },
      ];
      collections = [
        {
          name: "Обувки за деца",
          href: "/category_page/Deca/Lacoste Obuvki",
          imageSrc: firstTypeLacoste,
          imageAlt:
            "Brown leather key ring with brass metal loops and rivets on wood table.",
          description:
            "Keep your phone, keys, and wallet together, so you can lose everything at once.",
        },
        {
          name: "Облека за деца",
          href: "/category_page/Deca/Lacoste Obleka",
          imageSrc: secondTypeLacoste,
          imageAlt:
            "Natural leather mouse pad on white desk next to porcelain mug and keyboard.",
          description:
            "The rest of the house will still be a mess, but your desk will look great.",
        },
        {
          name: "Опрема",
          href: "/category_page/Deca/Lacoste Oprema",
          imageSrc: thirdTypeLacoste,
          imageAlt:
            "Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.",
          description:
            "Be more productive than enterprise project managers with a single piece of paper.",
        },
      ];

      firstBanner = firstBannerLacoste;
      secondBanner = secondBannerLacoste;
      thirdBanner = thirdBannerLacoste;
      slogan = lacosteSlogan;
      websiteLink = lacosteWebsite;
      btnText = lacosteBtnText;
      return {
        categories,
        collections,
        firstBanner,
        secondBanner,
        thirdBanner,
        slogan,
        websiteLink,
        btnText,
      };
    }
  },
};
