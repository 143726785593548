import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ChevronUpIcon,
  MenuIcon,
  SearchIcon,
  ShoppingCartIcon,
  XIcon,
} from "@heroicons/react/outline";

import { Link } from "react-router-dom";
import { menuBrands as brands } from "../../service/constants";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  numCartItems: number;
  search: () => void;
}

export const Menu: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex  lg:hidden"
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col mt-16 overflow-y-scroll">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <div>
                {brands.categories.map((category, idx) => (
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-200  bg-cipa-blue-500  hover:bg-cipa-blue-500 focus:outline-none focus-visible:ring focus-visible:ring-cipa-red-700 focus-visible:ring-opacity-75">
                          <span className="py-3 text-base font-bold">
                            {category.name}
                          </span>
                          <ChevronUpIcon
                            className={`${
                              open ? "transform rotate-180" : ""
                            } w-5 h-5 text-white mt-3`}
                          />
                        </Disclosure.Button>

                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <div className="grid grid-cols-1 items-start gap-y-10 gap-x-6">
                            <div className="grid grid-cols-1 gap-y-10 gap-x-6">
                              <div>
                                <p
                                  id={`mobile-featured-heading-${idx}`}
                                  className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                    category.catNames[0]
                                      ? "border-b-2 border-b-cipa-red-500"
                                      : ""
                                  }`}
                                >
                                  {category.catNames[0]}
                                </p>

                                <ul
                                  role="list"
                                  aria-labelledby={`mobile-featured-heading-${idx}`}
                                  className="mt-6 space-y-6"
                                >
                                  {category.featured.map((item, idx) => (
                                    <li key={idx} className="flex">
                                      <Link
                                        to={item.href}
                                        className="text-gray-500"
                                        onClick={() => setOpen(false)}
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>

                              <div>
                                <p
                                  id="mobile-categories-heading"
                                  className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                    category.catNames[1]
                                      ? "border-b-2 border-b-cipa-red-500"
                                      : ""
                                  }`}
                                >
                                  {category.catNames[1]}
                                </p>

                                <ul
                                  role="list"
                                  aria-labelledby="mobile-categories-heading"
                                  className="mt-6 space-y-6"
                                >
                                  {category.categories.map((item, idx) => (
                                    <li key={idx} className="flex">
                                      <Link
                                        to={item.href}
                                        className="text-gray-500"
                                        onClick={() => setOpen(false)}
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>

                            <div className="grid grid-cols-1 gap-y-10 gap-x-6">
                              <div>
                                <p
                                  id="mobile-collection-heading"
                                  className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                    category.catNames[2]
                                      ? "border-b-2 border-b-cipa-red-500"
                                      : ""
                                  }`}
                                >
                                  {category.catNames[2]}
                                </p>
                                <ul
                                  role="list"
                                  aria-labelledby="mobile-collection-heading"
                                  className="mt-6 space-y-6"
                                >
                                  {category.collection.map((item, idx) => (
                                    <li key={idx} className="flex">
                                      <Link
                                        to={item.href}
                                        className="text-gray-500"
                                        onClick={() => {
                                          setOpen(false);
                                        }}
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>

                              {/* <div>
                                <p
                                  id="mobile-brand-heading"
                                  className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                    category.catNames[3]
                                      ? "border-b-2 border-b-cipa-red-500"
                                      : ""
                                  }`}
                                >
                                  {category.catNames[3]}
                                </p>
                                <ul
                                  role="list"
                                  aria-labelledby="mobile-brand-heading"
                                  className="mt-6 space-y-6"
                                >
                                  {category.brands.map((item, idx) => (
                                    <li key={idx} className="flex">
                                      <Link
                                        to={item.href}
                                        className="text-gray-500"
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div> */}
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
              <div className="py-10 px-4 space-y-6">
                {brands.pages.map((page, idx) => (
                  <div key={idx} className="flow-root">
                    <Link
                      to={page.href}
                      className="-m-2 p-2 block font-medium text-cipa-blue-600"
                      onClick={() => setOpen(false)}
                    >
                      {page.name}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <div className="bg-white sticky top-40">
        <div className="bg-cipa-blue-500 lg:bg-white max-w-7xl mx-auto px-4 sm:px-6 sticky top-0 ">
          <div className="lg:border-b-2 lg:border-b-grey-400">
            <div className="h-16 flex items-center justify-center mx-auto">
              {/* Logo (lg+) */}

              <div className="hidden h-full lg:flex mx-auto">
                {/* Mega menus */}

                <Popover.Group className="">
                  <div className="h-full flex justify-center space-x-5">
                    {brands.categories.map((category, categoryIdx) => (
                      <Popover key={categoryIdx} className="flex">
                        {({ open }) => (
                          <>
                            <div className="relative flex ">
                              <Popover.Button
                                className={classNames(
                                  open
                                    ? "border-cipa-red-700 text-cipa-red-700"
                                    : "border-transparent text-gray-700 hover:text-cipa-red-700",
                                  "relative z-0 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px  "
                                )}
                              >
                                {category.name}
                              </Popover.Button>
                            </div>

                            {open && (
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                {true && (
                                  <Popover.Panel className="absolute top-full inset-x-0 text-gray-500 sm:text-sm">
                                    {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                    {({ close }) => (
                                      <>
                                        <div
                                          className="absolute inset-0 top-1/2 bg-white shadow"
                                          aria-hidden="true"
                                        />

                                        <div className="relative bg-white z-10">
                                          <div className="max-w-7xl mx-auto px-8">
                                            <div className="grid grid-cols-2 items-start gap-y-10 gap-x-8 pt-10 pb-12">
                                              <div className="grid grid-cols-4 gap-y-10 gap-x-8 text-left">
                                                <div>
                                                  <p
                                                    id={`desktop-featured-heading-${categoryIdx}`}
                                                    className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                                      category.catNames[0]
                                                        ? "border-b-2 border-b-cipa-red-700"
                                                        : ""
                                                    }`}
                                                  >
                                                    {category.catNames[0]}
                                                  </p>

                                                  <ul
                                                    role="list"
                                                    aria-labelledby={`desktop-featured-heading-${categoryIdx}`}
                                                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                  >
                                                    {category.featured.map(
                                                      (item, idx) => (
                                                        <li
                                                          key={idx}
                                                          className="flex"
                                                        >
                                                          <Link
                                                            to={item.href}
                                                            className="hover:text-cipa-red-600"
                                                            onClick={() => {
                                                              setOpen(false);
                                                              close();
                                                            }}
                                                          >
                                                            {item.name}
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                                <div>
                                                  <p
                                                    id="desktop-categories-heading"
                                                    className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                                      category.catNames[1]
                                                        ? "border-b-2 border-b-cipa-red-700"
                                                        : ""
                                                    }`}
                                                  >
                                                    {category.catNames[1]}
                                                  </p>
                                                  <ul
                                                    role="list"
                                                    aria-labelledby="desktop-categories-heading"
                                                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                  >
                                                    {category.categories.map(
                                                      (item, idx) => (
                                                        <li
                                                          key={idx}
                                                          className="flex"
                                                        >
                                                          <Link
                                                            to={item.href}
                                                            className="hover:text-cipa-red-600"
                                                            onClick={() =>
                                                              close()
                                                            }
                                                          >
                                                            {item.name}
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                                <div>
                                                  <p
                                                    id="desktop-collection-heading"
                                                    className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                                      category.catNames[2]
                                                        ? "border-b-2 border-b-cipa-red-700"
                                                        : ""
                                                    }`}
                                                  >
                                                    {category.catNames[2]}
                                                  </p>
                                                  <ul
                                                    role="list"
                                                    aria-labelledby="desktop-collection-heading"
                                                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                  >
                                                    {category.collection.map(
                                                      (item, idx) => (
                                                        <li
                                                          key={idx}
                                                          className="flex"
                                                        >
                                                          <Link
                                                            to={item.href}
                                                            className="hover:text-cipa-red-600"
                                                            onClick={() => {
                                                              setOpen(false);
                                                              close();
                                                            }}
                                                          >
                                                            {item.name}
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                                <div>
                                                  <p
                                                    id="desktop-brand-heading"
                                                    className={`font-medium text-cipa-red-700 pb-5 text-left ${
                                                      category.catNames[3]
                                                        ? "border-b-2 border-b-cipa-red-700"
                                                        : ""
                                                    }`}
                                                  >
                                                    {category.catNames[3]}
                                                  </p>
                                                  <ul
                                                    role="list"
                                                    aria-labelledby="desktop-brand-heading"
                                                    className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                  >
                                                    {category.brands.map(
                                                      (item, idx) => (
                                                        <li
                                                          key={idx}
                                                          className="flex"
                                                        >
                                                          <Link
                                                            to={item.href}
                                                            className="hover:text-cipa-red-600"
                                                            onClick={() =>
                                                              close()
                                                            }
                                                          >
                                                            {item.name}
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>

                                              <div className="grid grid-cols-2 gap-y-10 gap-x-8">
                                                {category.special.map((sp) => (
                                                  <div
                                                    className={
                                                      sp.span2
                                                        ? "col-span-2"
                                                        : ""
                                                    }
                                                  >
                                                    <Link
                                                      to={sp.imgLink}
                                                      className="font-medium text-gray-900"
                                                      onClick={() => close()}
                                                    >
                                                      <img
                                                        src={sp.imgSrc}
                                                        className={`object-center hover:opacity-75 rounded-xl ${
                                                          sp.span2
                                                            ? "h-64 object-cover w-full"
                                                            : " "
                                                        }`}
                                                      />
                                                    </Link>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </Popover.Panel>
                                )}
                              </Transition>
                            )}
                          </>
                        )}
                      </Popover>
                    ))}

                    {brands.pages.map((page, idx) => (
                      <Link
                        key={idx}
                        to={page.href}
                        className="flex items-center text-sm font-medium text-cipa-red-700 hover:text-cipa-red-400"
                      >
                        {page.name}
                      </Link>
                    ))}
                  </div>
                </Popover.Group>
              </div>
              {/* Mobile menu and search (lg-) */}
              <div className="flex-1 flex items-center lg:hidden">
                <button
                  type="button"
                  className="-ml-2 bg-cipa-blue-500 p-2 rounded-md text-gray-400"
                  onClick={() => setOpen((old_state: boolean) => !old_state)}
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <Link to="/" className="lg:hidden ">
                <span className="sr-only">Workflow</span>
                <img
                  src="/CipaSportImages/CipaLogoMob.png"
                  alt=""
                  className="h-16 w-auto"
                />
              </Link>
              <div className="lg:hidden flex-1 flex items-center justify-end ">
                <div className="flex items-center lg:ml-8 lg:hidden">
                  <div className="flex space-x-8">
                    <div className="hidden lg:flex"></div>
                    <button
                      className="-m-2 p-2 text-gray-400 hover:text-cipa-red-600"
                      onClick={props.search}
                    >
                      <span className="sr-only">Search</span>
                      <SearchIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flow-root">
                    <Link
                      to="/shopping_cart"
                      className="group -m-2 p-2 flex items-center"
                    >
                      <ShoppingCartIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-cipa-red-600"
                        aria-hidden="true"
                      />
                      <span className="ml-2 text-sm font-medium text-gray-400 group-hover:text-cipa-red-600">
                        {props.numCartItems}
                      </span>
                      <span className="sr-only">items in cart, view bag</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
