export const ErrorPage: React.FC = () => {
  return (
    <div className="py-36">
      <h1 className="text-9xl text-cipa-red-600">404</h1>
      <h1 className="text-2xl text-cipa-red-600 mt-20">
        Страницата што ја баравте не постои.
      </h1>
    </div>
  );
};
