import React from "react";
import { Link } from "react-router-dom";

const women = "/CipaSportImages/BV4958-010_1.jpg";
export const RightOffer: React.FC = () => {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl">
        <div className="relative rounded-lg overflow-hidden lg:h-96">
          <div className="absolute inset-0">
            <img
              src={women}
              alt=""
              className=" object-center object-contain img-flip"
            />
          </div>
          <div aria-hidden="true" className="relative w-full h-60 lg:hidden" />
          <div aria-hidden="true" className="relative w-full h-32 lg:hidden" />

          <div className="absolute inset-x-0 bottom-0 bg-cipa-blue-700 bg-opacity-75 p-6 rounded-bl-lg rounded-br-lg backdrop-filter backdrop-blur sm:flex sm:items-center sm:justify-between lg:inset-y-0 lg:inset-x-auto lg:w-full h-36 md:h-20 lg:h-28 lg:mt-auto  lg:rounded-br-none lg:flex-col lg:items-start ">
            <div className="mx-auto mb-1">
              <h2 className="text-xl font-bold text-cipa-red-600 text-center">
                Женска Облека
              </h2>
            </div>
            <Link
              to="/category_page/Zeni/Obleka"
              className="mt-6 flex-shrink-0 flex bg-white bg-opacity-0 py-2 px-4 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white hover:bg-opacity-10 sm:mt-0 sm:ml-8 lg:ml-0 lg:w-full"
            >
              Погледни повеќе
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
