import {
  SearchCircleIcon,
  SearchIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";

interface Props {
  numCartItems: number;
  search: () => void;
}

export const NavBar: React.FC<Props> = (props: Props) => {
  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <header className="relative">
        <nav aria-label="Top">
          {/* Top navigation */}
          <div className=" bg-cipa-blue-500 py-10 hidden lg:block">
            <div className="max-w-7xl mx-auto h-10 px-4 flex sm:px-6 lg:px-8 ">
              {/* Currency selector */}
              <div className="lg:items-center lg:justify-start px-32 "></div>

              <div className=" lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6 hidden mx-auto  ">
                <Link to="/">
                  <span className="sr-only">CipaSport</span>

                  <img
                    src="/CipaSportImages/CipaLogo.png"
                    alt="logo"
                    className="h-28 w-auto sm:hidden lg:block"
                  />
                </Link>
              </div>

              <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-center lg:space-x-6 ">
                <div className="flex-1 flex items-center justify-end">
                  <div className="flex items-center lg:ml-8 ">
                    <div className="flex space-x-8 ">
                      <div className="hidden lg:flex ">
                        <button
                          className="-m-2 p-2 text-white hover:text-cipa-red-600"
                          onClick={props.search}
                        >
                          <span className="sr-only">Search</span>
                          <SearchIcon className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <span
                      className="mx-4 h-6 w-px bg-white lg:mx-6"
                      aria-hidden="true"
                    />

                    <div className="flow-root">
                      <Link
                        to="/shopping_cart"
                        className="group -m-2 p-2 flex items-center"
                      >
                        <ShoppingCartIcon
                          className="flex-shrink-0 h-6 w-6 text-white group-hover:text-cipa-red-600"
                          aria-hidden="true"
                        />
                        <span className="ml-2 text-sm font-medium text-white group-hover:text-cipa-red-600">
                          {props.numCartItems}
                        </span>
                        <span className="sr-only">items in cart, view bag</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};
