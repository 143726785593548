import { facebookIcon } from "../componenets/facebookIcon";
import { instagramIcon } from "../componenets/instagramIcon";
import { ProductsJson } from "./ProductsJson";
import { Product } from "./interfaces";

const patiki = "/CipaSportImages/DA8697-800_1.jpg";
const maici = "/CipaSportImages/CZ9305-808_1.jpg";
const dukseri = "/CipaSportImages/DA1173-657_1.jpg";
export const categoriesCallouts = [
  {
    description: "Патики",
    imageSrc: patiki,
    imageAlt: "Патики",
    href: "/category_page/Сите/Патики",
  },
  {
    description: "Маици",
    imageSrc: maici,
    imageAlt: "Маици",
    href: "/category_page/Сите/Maici",
  },
  {
    description: "Дуксери",
    imageSrc: dukseri,
    imageAlt: "Дуксери",
    href: "/category_page/Сите/Dukseri",
  },
];

const ranci = "/CipaSportImages/BA6030-013_1.jpg";
const jakni = "/CipaSportImages/740390-6554_home.png";
const oprema = "/CipaSportImages/CV4697-482_home.png";
export const brandsCallouts = [
  {
    name: "Desk and Office",
    description: "Ранци",
    imageSrc: ranci,
    imageAlt:
      "Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.",
    href: "/category_page/Сите/Ranci",
  },
  {
    name: "Self-Improvement",
    description: "Јакни",
    imageSrc: jakni,
    imageAlt:
      "Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.",
    href: "/category_page/Сите/Jakni",
  },
  {
    name: "Travel",
    description: "Спортска Опрема",
    imageSrc: oprema,
    imageAlt: "Collection of four insulated travel bottles on wooden shelf.",
    href: "/category_page/Сите/Спортска Опрема",
  },
];

export const brandListCategories = [
  {
    name: "Nike",
    href: "#",
    imageSrc:
      "https://www.seekpng.com/png/full/391-3910645_nike-logo-transparent-white-pictures-png-nike-png.png",
  },
  {
    name: "Adidas",
    href: "#",
    imageSrc: "https://www.pngarts.com/files/8/Adidas-Logo-PNG-Image.png",
  },
  {
    name: "Converse",
    href: "#",
    imageSrc: "https://logowik.com/content/uploads/images/880_converse.jpg",
  },
  {
    name: "Reebok",
    href: "#",
    imageSrc:
      "https://preview.thenewsmarket.com/Previews/RBOK/StillAssets/1920x1080/551064.png",
  },
  {
    name: "Timberland",
    href: "#",
    imageSrc:
      "https://www.nicepng.com/png/full/162-1625327_timberland-logo.png",
  },
  {
    name: "Champion",
    href: "#",
    imageSrc: "https://wallpaperaccess.com/full/1765937.png",
  },
  {
    name: "The North Face",
    href: "#",
    imageSrc:
      "https://www.seekpng.com/png/detail/40-404862_north-face-logo-logo-vector-the-north-face.png",
  },
  {
    name: "Lotto",
    href: "#",
    imageSrc:
      " https://logos-marques.com/wp-content/uploads/2020/07/Loto-logo.png",
  },
];

const VansLogo = require("../images/Vans.png");
const LacosteLogo = require("../images/Lacoste.png");
const KidsColorLogo = require("../images/colorKids.png");
const JackWolfskinLogo = require("../images/jackWolfskin.png");
const NewBalanceLogo = require("../images/newBalance.png");
const HellyHansenLogo = require("../images/hellyHansen.png");
const NikeLogo = require("../images/Nike.png");
export const brandCarouselBrands = [
  {
    name: "Nike",
    href: "/showcase/nike",
    imageSrc: NikeLogo,
  },
  {
    name: "Vans",
    href: "/showcase/vans",
    imageSrc: VansLogo,
  },
  {
    name: "Lacoste",
    href: "/showcase/lacoste",
    imageSrc: LacosteLogo,
  },
  {
    name: "Jack Wolfeskin",
    href: "/showcase/jack_wolf",
    imageSrc: JackWolfskinLogo,
  },
  {
    name: "New Blance",
    href: "/showcase/new_balance",
    imageSrc: NewBalanceLogo,
  },
  {
    name: "Color Kids",
    href: "/showcase/color_kids",
    imageSrc: KidsColorLogo,
  },
  {
    name: "Helly Hansen",
    href: "/showcase/helley_hansen",
    imageSrc: HellyHansenLogo,
  },
];

export const orderDetailpages = [
  {
    name: "Корисничка кошничка",
    href: "/shopping_cart",
    current: false,
  },
  { name: "Потврди", href: "/check_out", current: false },
  { name: "Информации за нарачката", href: "#", current: true },
];

const IconOrder = require("../images/my-orders.png");
const Icon24 = require("../images/24-hours.png");
const IconDelivery = require("../images/delivery-truck.png");

export const incentives = [
  {
    name: "Професионална достава",
    description:
      "It's not actually free we just price it into the products. Someone's paying for it, and it's not us.",
    imageSrc: IconOrder,
  },
  {
    name: "24/7 корисничка поддршка",
    description:
      "Our AI chat widget is powered by a naive series of if/else statements. Guaranteed to irritate.",
    imageSrc: Icon24,
  },
  {
    name: "Брза нарачка",
    description:
      "Look how fast that cart is going. What does this mean for the actual experience? I don't know.",
    imageSrc: IconDelivery,
  },
];

export const footerLinks = {
  main: [
    { name: "Услови на користење", href: "#" },
    { name: "Политика на приватност", href: "/privacy_policy" },
    { name: "Колачиња", href: "#" },
    { name: "Начин на купување", href: "#" },
    { name: "Испорака", href: "#" },
    { name: "Контакт", href: "/contact_page" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/cipasport",
      icon: facebookIcon,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/cipasport/",
      icon: instagramIcon,
    },
  ],
};

const firstImage = require("../images/carousel/Carousel 1.jpg");
const secondImage = require("../images/carousel/Carousel 2.jpg");
const thirdImage = require("../images/carousel/Carousel 3.jpg");
export const slickCarouselimages = [
  {
    imgHref: "/discount_list",
    imgSrc: firstImage,
  },
  {
    imgHref: "/showcase/nike",
    imgSrc: secondImage,
  },
  {
    imgHref: "/showcase/jack_wolf",
    imgSrc: thirdImage,
  },
  {
    imgHref: "/discount_list",
    imgSrc: firstImage,
  },
  {
    imgHref: "/showcase/nike",
    imgSrc: secondImage,
  },
  {
    imgHref: "/showcase/jack_wolf",
    imgSrc: thirdImage,
  },
];

//MEN
let menFootwear = ProductsJson.getMenFootwear();
menFootwear =
  menFootwear[Math.floor(Math.random() * menFootwear.length)].Images[0];

let menApparel = ProductsJson.getMenApparel();
menApparel =
  menApparel[Math.floor(Math.random() * menApparel.length)].Images[0];

let menEquipment = ProductsJson.getMenEquipment();
menEquipment =
  menEquipment[Math.floor(Math.random() * menEquipment.length)].Images[0];

//WOMEN

let womenFootwear = ProductsJson.getWomenFootwear();
womenFootwear =
  womenFootwear[Math.floor(Math.random() * womenFootwear.length)].Images[0];

let womenApparel = ProductsJson.getWomenApparel();
womenApparel =
  womenApparel[Math.floor(Math.random() * womenApparel.length)].Images[0];

let womenEquipment = ProductsJson.getWomenEquipment();
womenEquipment =
  womenEquipment[Math.floor(Math.random() * womenEquipment.length)].Images[0];

//KIDS

let kidsFootwear = ProductsJson.getKidsFootwear();
kidsFootwear =
  kidsFootwear[Math.floor(Math.random() * kidsFootwear.length)].Images[0];

let kidsApparel = ProductsJson.getKidsApparel();
kidsApparel =
  kidsApparel[Math.floor(Math.random() * kidsApparel.length)].Images[0];

let kidsEquipment = ProductsJson.getKidsEquipment();
kidsEquipment =
  kidsEquipment[Math.floor(Math.random() * kidsEquipment.length)].Images[0];

//Little_KIDS

let littleKidsFootwear = ProductsJson.getLittleKidsFootwear();
littleKidsFootwear =
  littleKidsFootwear[Math.floor(Math.random() * littleKidsFootwear.length)]
    .Images[0];

let littleKidsApparel = ProductsJson.getLittleKidsApparel();
littleKidsApparel =
  littleKidsApparel[Math.floor(Math.random() * littleKidsApparel.length)]
    .Images[0];

let littleKidsEquipment = ProductsJson.getLittleKidsEquipment();
littleKidsEquipment =
  littleKidsEquipment[Math.floor(Math.random() * littleKidsEquipment.length)]
    .Images[0];

//Infatns

let infantsFootwear = ProductsJson.getInfantsFootwear();
infantsFootwear =
  infantsFootwear[Math.floor(Math.random() * infantsFootwear.length)].Images[0];

let infantsApparel = ProductsJson.getInfantsApparel();
infantsApparel =
  infantsApparel[Math.floor(Math.random() * infantsApparel.length)].Images[0];

let infantsEquipment = ProductsJson.getInfantsEquipment();
infantsEquipment =
  infantsEquipment[Math.floor(Math.random() * infantsEquipment.length)]
    .Images[0];

//Сезонски

let firstDiscountImage = ProductsJson.getAllProducts().filter(
  (f: Product) => f.Discount !== 0
);
firstDiscountImage =
  firstDiscountImage[Math.floor(Math.random() * firstDiscountImage.length)]
    .Images[0];

let secondDiscountImage = ProductsJson.getAllProducts().filter(
  (f: Product) => f.Discount !== 0
);
secondDiscountImage =
  secondDiscountImage[Math.floor(Math.random() * secondDiscountImage.length)]
    .Images[0];

let thirdDiscountImage = ProductsJson.getAllProducts().filter(
  (f: Product) => f.Discount !== 0
);
thirdDiscountImage =
  thirdDiscountImage[Math.floor(Math.random() * thirdDiscountImage.length)]
    .Images[0];

export const menuBrands = {
  categories: [
    {
      name: "РАСПРОДАЖБА",
      featured: [
        { name: "Обувки", href: "/discount_list?category=Mazi Obuvki" },
        { name: "Текстил", href: "/discount_list?cateogory=Mazi Obleka" },
        { name: "Опрема", href: "/discount_list?category=Mazi Oprema" },
      ],
      collection: [
        { name: "Обувки", href: "/discount_list?category=Zeni Obuvki" },
        { name: "Текстил", href: "/discount_list?category=Zeni Obleka" },
        { name: "Опрема", href: "/discount_list?category=Zeni Oprema" },
      ],
      categories: [
        { name: "Обувки", href: "/discount_list?category=Deca Obuvki" },
        { name: "Текстил", href: "/discount_list?category=Deca Obleka" },
        { name: "Опрема", href: "/discount_list?category=Deca Oprema" },
      ],
      brands: [{ name: "", href: "/discount_list" }],
      catNames: ["МАЖИ", "ЖЕНИ", "ДЕЦА", false],
      special: [
        // {
        //   title: "Обувки",
        //   imgSrc: firstDiscountImage,
        //   span2: false,
        //   imgLink: "#",
        // },
        // {
        //   title: "Облека",
        //   imgSrc: secondDiscountImage,
        //   span2: false,
        //   imgLink: "#",
        // },
        // {
        //   title: "Опрема",
        //   imgSrc: thirdDiscountImage,
        //   span2: true,
        //   imgLink: "#",
        // },
      ],
    },

    {
      name: "МАЖИ",
      featured: [
        { name: "Air Max", href: "/category_page/Mazi/Air_Max" },
        { name: "Jordan", href: "/category_page/Mazi/Jordan" },
        { name: "Сите Обувки", href: "/category_page/Mazi/Obuvki" },
      ],
      collection: [
        { name: "Чорапи", href: "/category_page/Mazi/Corapi" },
        { name: "Капи", href: "/category_page/Mazi/Kapi" },
        { name: "Ранци", href: "/category_page/Mazi/Ranci" },
        { name: "Торби", href: "/category_page/Mazi/Torbi" },
        { name: "Нараквици", href: "/category_page/Mazi/Narakvici" },
        { name: "Цела Оперма", href: "/category_page/Mazi/Oprema" },
      ],
      categories: [
        { name: "Дуксери", href: "/category_page/Mazi/Dukseri" },
        { name: "Комплети", href: "/category_page/Mazi/Kompleti" },
        { name: "Панталони", href: "/category_page/Mazi/Pantaloni" },
        { name: "Маици", href: "/category_page/Mazi/Maici" },
        { name: "Јакни", href: "/category_page/Mazi/Jakni" },
        { name: "Шорцеви", href: "/category_page/Mazi/Sorcevi" },
        { name: "Цела Облека", href: "/category_page/Mazi/Obleka" },
      ],
      brands: [],
      catNames: ["ОБУВКИ", "ОБЛЕКА", "ОПРЕМА", false],
      special: [
        {
          title: "Обувки",

          imgSrc: menFootwear,
          span2: false,
          imgLink: "/category_page/Mazi/Obuvki",
        },
        {
          title: "Облека",

          imgSrc: menApparel,
          span2: false,
          imgLink: "/category_page/Mazi/Obleka",
        },
        {
          title: "Опрема",

          imgSrc: menEquipment,
          span2: true,
          imgLink: "/category_page/Mazi/Oprema",
        },
      ],
    },
    {
      name: "ЖЕНИ",
      featured: [
        { name: "Air Max", href: "/category_page/Zeni/Air_Max" },
        { name: "Li Na", href: "/category_page/Zeni/Lina" },
        { name: "Сите Обувки", href: "/category_page/Zeni/Obuvki" },
      ],
      collection: [
        { name: "Капи", href: "/category_page/Zeni/Kapi" },
        { name: "Ранци", href: "/category_page/Zeni/Ranci" },
        { name: "Торби", href: "/category_page/Zeni/Torbi" },
        { name: "Чорапи", href: "/category_page/Zeni/Corapi" },
        { name: "Цела Опрема", href: "/category_page/Zeni/Oprema" },
      ],
      categories: [
        { name: "Дуксери", href: "/category_page/Zeni/Dukseri" },
        { name: "Комплети", href: "/category_page/Zeni/Kompleti" },
        { name: "Маици", href: "/category_page/Zeni/Maici" },
        { name: "Панталони", href: "/category_page/Zeni/Pantaloni" },
        { name: "Хеланки", href: "/category_page/Zeni/Helanki" },
        { name: "Шушкавци", href: "/category_page/Zeni/Suskavci" },
        { name: "Шорцеви", href: "/category_page/Zeni/Sorcevi" },
        { name: "Фустани", href: "/category_page/Zeni/Fustani" },
        { name: "Елеци", href: "/category_page/Zeni/Eleci" },
        { name: "Цела Облека", href: "/category_page/Zeni/Obleka" },
      ],
      brands: [],
      catNames: ["ОБУВКИ", "ОБЛЕКА", "ОПРЕМА", false],
      special: [
        {
          title: "Обувки",

          imgSrc: womenFootwear,
          span2: false,
          imgLink: "/category_page/Zeni/Obuvki",
        },
        {
          title: "Облека",

          imgSrc: womenApparel,
          span2: false,
          imgLink: "/category_page/Zeni/Obleka",
        },
        {
          title: "Опрема",

          imgSrc: womenEquipment,
          span2: true,
          imgLink: "/category_page/Zeni/Oprema",
        },
      ],
    },
    {
      name: "ДЕЦА",
      featured: [
        { name: "Air Max", href: "/category_page/Deca/Air_Max" },
        { name: "Revolution", href: "/category_page/Deca/Revolution" },
        { name: "Court Borough", href: "/category_page/Deca/Court_Borough" },

        { name: "Сите Обувки", href: "/category_page/Deca/Obuvki" },
      ],
      collection: [
        { name: "Ранци", href: "/category_page/Deca/Ranci" },
        { name: "Капи", href: "/category_page/Deca/Kapi" },
        { name: "Нараквици", href: "/category_page/Deca/Narakvici" },
        { name: "Цела Опрема", href: "/category_page/Deca/Oprema" },
      ],
      categories: [
        { name: "Дуксери", href: "/category_page/Deca/Dukseri" },
        { name: "Комплети", href: "/category_page/Deca/Kompleti" },
        { name: "Панталони", href: "/category_page/Deca/Pantaloni" },
        { name: "Маици", href: "/category_page/Deca/Maici" },
        { name: "Јакни", href: "/category_page/Deca/Jakni" },
        { name: "Шушкавци", href: "/category_page/Deca/Suskavci" },
        { name: "Фустани", href: "/category_page/Deca/Fustani" },
        { name: "Шорцеви", href: "/category_page/Deca/Sorcevi" },
        { name: "Хеланки", href: "/category_page/Deca/Helanki" },
        { name: "Цела Облека", href: "/category_page/Deca/Obleka" },
      ],
      brands: [],
      catNames: ["ОБУВКИ", "ОБЛЕКА", "ОПРЕМА", false],
      special: [
        {
          title: "Обувки",

          imgSrc: kidsFootwear,
          span2: false,
          imgLink: "/category_page/Deca/Obuvki",
        },
        {
          title: "Облека",

          imgSrc: kidsApparel,
          span2: false,
          imgLink: "/category_page/Deca/Obleka",
        },
        {
          title: "Опрема",

          imgSrc: kidsEquipment,
          span2: true,
          imgLink: "/category_page/Deca/Oprema",
        },
      ],
    },
    {
      name: "МАЛИ ДЕЦА",
      featured: [
        { name: "Air Max", href: "/category_page/Mali_Deca/Air_Max" },
        { name: "Revolution", href: "/category_page/Mali_Deca/Revolution" },
        {
          name: "Court Borough",
          href: "/category_page/Mali_Deca/Court_Borough",
        },

        { name: "Сите Обувки", href: "/category_page/Mali_Deca/Obuvki" },
      ],
      collection: [
        { name: "Чорапи", href: "/category_page/Mali_Deca/Corapi" },
        { name: "Ранци", href: "/category_page/Mali_Deca/Ranci" },
        { name: "Цела Опрема", href: "/category_page/Mali_Deca/Oprema" },
      ],
      categories: [
        { name: "Маици", href: "/category_page/Mali_Deca/Maici" },
        { name: "Јакни", href: "/category_page/Mali_Deca/Jakni" },
        { name: "Комбинезони", href: "/category_page/Mali_Deca/Kombinezoni" },
        { name: "Комплети", href: "/category_page/Mali_Deca/Kompleti" },

        { name: "Цела Облека", href: "/category_page/Mali_Deca/Obleka" },
      ],
      brands: [],
      catNames: ["ОБУВКИ", "ОБЛЕКА", "ОПРЕМА", false],
      special: [
        {
          title: "Обувки",

          imgSrc: littleKidsFootwear,
          span2: false,
          imgLink: "/category_page/Mali_Deca/Obuvki",
        },
        {
          title: "Облека",

          imgSrc: littleKidsApparel,
          span2: false,
          imgLink: "/category_page/Mali_Deca/Obleka",
        },
        {
          title: "Опрема",

          imgSrc: littleKidsEquipment,
          span2: true,
          imgLink: "/category_page/Mali_Deca/Oprema",
        },
      ],
    },
    {
      name: "БЕБИЊА",
      featured: [
        { name: "Air Max", href: "/category_page/Bebinja/Air_Max" },
        { name: "Revolution", href: "/category_page/Bebinja/Revolution" },
        { name: "Court Borough", href: "/category_page/Bebinja/Court_Borough" },
        { name: "Сите Обувки", href: "/category_page/Bebinja/Obuvki" },
      ],
      collection: [
        { name: "Чорапи", href: "/category_page/Bebinja/Corapi" },
        { name: "Ранци", href: "/category_page/Bebinja/Ranci" },
        { name: "Капи", href: "/category_page/Bebinja/Kapi" },

        { name: "Цела Опрема", href: "/category_page/Bebinja/Oprema" },
      ],
      categories: [
        { name: "Маици", href: "/category_page/Bebinja/Maici" },
        { name: "Јакни", href: "/category_page/Bebinja/Jakni" },
        { name: "Комбинезони", href: "/category_page/Bebinja/Kombinezoni" },
        { name: "Комплети", href: "/category_page/Bebinja/Kompleti" },

        { name: "Цела Облека", href: "/category_page/Bebinja/Obleka" },
      ],
      brands: [],
      catNames: ["ОБУВКИ", "ОБЛЕКА", "ОПРЕМА", false],
      special: [
        {
          title: "Обувки",

          imgSrc: infantsFootwear,
          span2: false,
          imgLink: "/category_page/Bebinja/Obuvki",
        },
        {
          title: "Облека",

          imgSrc: infantsApparel,
          span2: false,
          imgLink: "/category_page/Bebinja/Obleka",
        },
        {
          title: "Опрема",

          imgSrc: infantsEquipment,
          span2: true,
          imgLink: "/category_page/Bebinja/Oprema",
        },
      ],
    },
  ],
  pages: [
    { name: "NIKE", href: "/showcase/nike" },
    { name: "VANS", href: "/showcase/vans" },
    { name: "LACOSTE", href: "/showcase/lacoste" },
    { name: "JACK WOLFSKIN", href: "/showcase/jack_wolf" },
    { name: "NEW BALANCE", href: "/showcase/new_balance" },
    { name: "COLOR KIDS", href: "/showcase/color_kids" },
    { name: "HELLY HANSEN", href: "/showcase/helley_hansen" },
  ],
};

export const pathDict: Record<string, string> = {
  Mazi: "Мажи",
  Zeni: "Жени",
  Deca: "Деца",
  FirstName: "Chris",
  Mali_Deca: "Мали Деца",
  Bebinja: "Бебиња",
  Air_Max: "Air Max",
  Jordan: "Jordan",
  Obuvki: "Обувки",
  Obleka: "Облека",
  Oprema: "Опрема",
  Dukseri: "Дуксери",
  Kompleti: "Комплети",
  Pantaloni: "Панталони",
  Maici: "Маици",
  Jakni: "Јакни",
  Sorcevi: "Шорцеви",
  Corapi: "Чорапи",
  Kapi: "Капи",
  Ranci: "Ранци",
  Torbi: "Торби",
  Narakvici: "Нараквици",
  Lina: "Li na",
  Helanki: "Хеланки",
  Suskavci: "Шушкавци",
  Fustani: "Фустани",
  Eleci: "Елеци",
  Revolution: "Revolution",
  Court_Borough: "Court Borough",
  Kombinezoni: "Комбинезони",
};

export default function shuffle(array: Product[]) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
