import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { Page } from "../../service/interfaces";

interface Props {
  Pages: Page[];
}

export const BreadCrumbs: React.FC<Props> = (props: Props) => {
  const pages = props.Pages;
  return (
    <nav className="flex overflow-x-hidden" aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex flex-row items-center space-x-2 md:space-x-4"
      >
        <li>
          <div>
            <Link to="/" className="text-cipa-blue-700 hover:text-cipa-red-600">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page, idx) => (
          <li
            key={idx}
            className={idx === 0 || idx === 1 ? "block" : "hidden md:block"}
          >
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />

              {page.current ? (
                <label
                  className="ml-4 text-sm font-medium text-cipa-red-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </label>
              ) : (
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-cipa-blue-700 hover:text-cipa-red-600"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
