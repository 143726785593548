import { BreadCrumbs } from "../breadcrumbs/breadcrumbs";
import { orderDetailpages as pages } from "../../service/constants";

export const OrderDetail: React.FC = () => {
  return (
    <div className="bg-white ">
      <main className="max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <BreadCrumbs Pages={pages} />
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 mt-20 mb-10 text-middle">
          Ви благодариме за нарачката , ќе бидете контактирани во најбрзо време
          за достапноста на производите и потврдување на вашата нарачка.
        </h1>
      </main>
    </div>
  );
};
