import { ProductsJson } from "./ProductsJson";
import { Product } from "../service/interfaces";

export const TypeOfProductsToReturn = {
  getProducts: (category: string | undefined, type: string | undefined) => {
    if (category === "Сите") {
      if (type === "Color Kids Obuvki") {
        let colorKidsObuvki = ProductsJson.getKidsFootwear().filter(
          (f: Product) => f.Brand === "Color Kids"
        );
        colorKidsObuvki = colorKidsObuvki.concat(
          ProductsJson.getLittleKidsFootwear().filter(
            (f: Product) => f.Brand === "Color Kids"
          )
        );
        colorKidsObuvki = colorKidsObuvki.concat(
          ProductsJson.getInfantsFootwear().filter(
            (f: Product) => f.Brand === "Color Kids"
          )
        );
        return colorKidsObuvki;
      }
      if (type === "Color Kids Obleka") {
        let colorKidsObleka = ProductsJson.getKidsApparel().filter(
          (f: Product) => f.Brand === "Color Kids"
        );
        colorKidsObleka = colorKidsObleka.concat(
          ProductsJson.getLittleKidsApparel().filter(
            (f: Product) => f.Brand === "Color Kids"
          )
        );
        colorKidsObleka = colorKidsObleka.concat(
          ProductsJson.getInfantsApparel().filter(
            (f: Product) => f.Brand === "Color Kids"
          )
        );
        return colorKidsObleka;
      }
      if (type === "Color Kids Oprema") {
        let colorKidsOprema = ProductsJson.getKidsEquipment().filter(
          (f: Product) => f.Brand === "Color Kids"
        );
        colorKidsOprema = colorKidsOprema.concat(
          ProductsJson.getLittleKidsEquipment().filter(
            (f: Product) => f.Brand === "Color Kids"
          )
        );
        colorKidsOprema = colorKidsOprema.concat(
          ProductsJson.getInfantsEquipment().filter(
            (f: Product) => f.Brand === "Color Kids"
          )
        );
        return colorKidsOprema;
      }
      if (type === "Патики") {
        let patiki = ProductsJson.getMenFootwear();
        patiki = patiki.concat(ProductsJson.getWomenFootwear());
        patiki = patiki.concat(ProductsJson.getKidsFootwear());
        patiki = patiki.concat(ProductsJson.getLittleKidsFootwear());
        patiki = patiki.concat(ProductsJson.getInfantsFootwear());
        return patiki;
      }
      if (type === "Maici") {
        let maici = ProductsJson.getMenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("MAICA") ||
            f.Product_Name.includes("SHIRT") ||
            f.Product_Name.includes("BLUZA")
        );

        maici = maici.concat(
          ProductsJson.getWomenApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("MAICA") ||
              f.Product_Name.includes("SHIRT") ||
              f.Product_Name.includes("BLUZA")
          )
        );
        maici = maici.concat(
          ProductsJson.getKidsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("MAICA") ||
              f.Product_Name.includes("SHIRT") ||
              f.Product_Name.includes("BLUZA")
          )
        );
        maici = maici.concat(
          ProductsJson.getLittleKidsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("MAICA") ||
              f.Product_Name.includes("SHIRT") ||
              f.Product_Name.includes("BLUZA")
          )
        );
        maici = maici.concat(
          ProductsJson.getInfantsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("MAICA") ||
              f.Product_Name.includes("SHIRT") ||
              f.Product_Name.includes("BLUZA")
          )
        );

        return maici;
      }
      if (type === "Dukseri") {
        let dukseri = ProductsJson.getMenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("HOODIE") || f.Product_Name.includes("DUKS")
        );
        dukseri = dukseri.concat(
          ProductsJson.getWomenApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("HOODIE") ||
              f.Product_Name.includes("DUKS")
          )
        );
        dukseri = dukseri.concat(
          ProductsJson.getKidsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("HOODIE") ||
              f.Product_Name.includes("DUKS")
          )
        );
        dukseri = dukseri.concat(
          ProductsJson.getLittleKidsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("HOODIE") ||
              f.Product_Name.includes("DUKS")
          )
        );
        dukseri = dukseri.concat(
          ProductsJson.getInfantsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("HOODIE") ||
              f.Product_Name.includes("DUKS")
          )
        );
        return dukseri;
      }
      if (type === "Ranci") {
        let ranci = ProductsJson.getMenEquipment().filter((f: Product) =>
          f.Product_Name.includes("RANEC")
        );
        ranci = ranci.concat(
          ProductsJson.getWomenEquipment().filter((f: Product) =>
            f.Product_Name.includes("RANEC")
          )
        );
        ranci = ranci.concat(
          ProductsJson.getKidsEquipment().filter((f: Product) =>
            f.Product_Name.includes("RANEC")
          )
        );
        ranci = ranci.concat(
          ProductsJson.getLittleKidsEquipment().filter((f: Product) =>
            f.Product_Name.includes("RANEC")
          )
        );
        ranci = ranci.concat(
          ProductsJson.getInfantsEquipment().filter((f: Product) =>
            f.Product_Name.includes("RANEC")
          )
        );
        return ranci;
      }
      if (type === "Jakni") {
        let jakni = ProductsJson.getMenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("JAKNA") ||
            f.Product_Name.includes("JACKET")
        );
        jakni = jakni.concat(
          ProductsJson.getWomenApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("JAKNA") ||
              f.Product_Name.includes("JACKET")
          )
        );
        jakni = jakni.concat(
          ProductsJson.getKidsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("JAKNA") ||
              f.Product_Name.includes("JACKET")
          )
        );
        jakni = jakni.concat(
          ProductsJson.getLittleKidsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("JAKNA") ||
              f.Product_Name.includes("JACKET")
          )
        );
        jakni = jakni.concat(
          ProductsJson.getInfantsApparel().filter(
            (f: Product) =>
              f.Product_Name.includes("JAKNA") ||
              f.Product_Name.includes("JACKET")
          )
        );
        return jakni;
      }
      if (type === "Спортска Oprema") {
        let lina = ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Product_Name.includes("LINA")
        );
        lina = lina.concat(
          ProductsJson.getWomenApparel().filter((f: Product) =>
            f.Product_Name.includes("LINA")
          )
        );
        lina = lina.concat(
          ProductsJson.getWomenEquipment().filter((f: Product) =>
            f.Product_Name.includes("LINA")
          )
        );

        return lina;
      }
      if (type === "Jack Wolfskin Obuvki") {
        let jackWolfskinPatiki = ProductsJson.getMenFootwear().filter(
          (f: Product) => f.Brand === "Jack Wolf"
        );
        jackWolfskinPatiki = jackWolfskinPatiki.concat(
          ProductsJson.getWomenFootwear().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinPatiki = jackWolfskinPatiki.concat(
          ProductsJson.getKidsFootwear().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinPatiki = jackWolfskinPatiki.concat(
          ProductsJson.getLittleKidsFootwear().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinPatiki = jackWolfskinPatiki.concat(
          ProductsJson.getInfantsFootwear().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );

        return jackWolfskinPatiki;
      }
      if (type === "Jack Wolfskin Jakni") {
        let jackWolfskinJakni = ProductsJson.getMenApparel().filter(
          (f: Product) =>
            (f.Product_Name.includes("JAKNA") ||
              f.Product_Name.includes("JACKET")) &&
            f.Brand === "Jack Wolf"
        );
        jackWolfskinJakni = jackWolfskinJakni.concat(
          ProductsJson.getWomenApparel().filter(
            (f: Product) =>
              (f.Product_Name.includes("JAKNA") ||
                f.Product_Name.includes("JACKET")) &&
              f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinJakni = jackWolfskinJakni.concat(
          ProductsJson.getKidsApparel().filter(
            (f: Product) =>
              (f.Product_Name.includes("JAKNA") ||
                f.Product_Name.includes("JACKET")) &&
              f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinJakni = jackWolfskinJakni.concat(
          ProductsJson.getLittleKidsApparel().filter(
            (f: Product) =>
              (f.Product_Name.includes("JAKNA") ||
                f.Product_Name.includes("JACKET")) &&
              f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinJakni = jackWolfskinJakni.concat(
          ProductsJson.getInfantsApparel().filter(
            (f: Product) =>
              (f.Product_Name.includes("JAKNA") ||
                f.Product_Name.includes("JACKET")) &&
              f.Brand === "Jack Wolf"
          )
        );
        return jackWolfskinJakni;
      }
      if (type === "Jack Wolfskin Oprema") {
        let jackWolfskinOprema = ProductsJson.getMenEquipment().filter(
          (f: Product) => f.Brand === "Jack Wolf"
        );
        jackWolfskinOprema = jackWolfskinOprema.concat(
          ProductsJson.getWomenEquipment().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinOprema = jackWolfskinOprema.concat(
          ProductsJson.getKidsEquipment().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinOprema = jackWolfskinOprema.concat(
          ProductsJson.getLittleKidsEquipment().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );
        jackWolfskinOprema = jackWolfskinOprema.concat(
          ProductsJson.getInfantsEquipment().filter(
            (f: Product) => f.Brand === "Jack Wolf"
          )
        );

        return jackWolfskinOprema;
      }
      if (type === "Helly Hansen Obuvki") {
        let HelleyHansenObuvki = ProductsJson.getMenFootwear().filter(
          (f: Product) => f.Brand === "Helly Hansen"
        );
        HelleyHansenObuvki = HelleyHansenObuvki.concat(
          ProductsJson.getWomenFootwear().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenObuvki = HelleyHansenObuvki.concat(
          ProductsJson.getKidsFootwear().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenObuvki = HelleyHansenObuvki.concat(
          ProductsJson.getLittleKidsFootwear().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenObuvki = HelleyHansenObuvki.concat(
          ProductsJson.getInfantsFootwear().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );

        return HelleyHansenObuvki;
      }
      if (type === "Helly Hansen Obleka") {
        let HelleyHansenObleka = ProductsJson.getMenApparel().filter(
          (f: Product) => f.Brand === "Helly Hansen"
        );
        HelleyHansenObleka = HelleyHansenObleka.concat(
          ProductsJson.getWomenApparel().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenObleka = HelleyHansenObleka.concat(
          ProductsJson.getKidsApparel().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenObleka = HelleyHansenObleka.concat(
          ProductsJson.getLittleKidsApparel().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenObleka = HelleyHansenObleka.concat(
          ProductsJson.getInfantsApparel().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );

        return HelleyHansenObleka;
      }
      if (type === "Helly Hansen Oprema") {
        let HelleyHansenOprema = ProductsJson.getMenEquipment().filter(
          (f: Product) => f.Brand === "Helly Hansen"
        );
        HelleyHansenOprema = HelleyHansenOprema.concat(
          ProductsJson.getWomenEquipment().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenOprema = HelleyHansenOprema.concat(
          ProductsJson.getKidsEquipment().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenOprema = HelleyHansenOprema.concat(
          ProductsJson.getLittleKidsEquipment().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );
        HelleyHansenOprema = HelleyHansenOprema.concat(
          ProductsJson.getInfantsEquipment().filter(
            (f: Product) => f.Brand === "Helly Hansen"
          )
        );

        return HelleyHansenOprema;
      }
    }

    //Men
    else if (category === "Mazi") {
      //Obuvki
      if (type === "Air_Max") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Product_Name.includes("AIR MAX")
        );
      } else if (type === "Jordan") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Product_Name.includes("JORDAN")
        );
      } else if (type === "Obuvki") {
        return ProductsJson.getMenFootwear();
      }

      //BRANDS
      else if (type === "Nike Obuvki") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Brand.includes("Nike")
        );
      } else if (type === "Nike Obleka") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Brand.includes("Nike")
        );
      } else if (type === "Vans Obuvki") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Brand.includes("Vans")
        );
      } else if (type === "Vans Obleka") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Brand.includes("Vans")
        );
      } else if (type === "Lacoste Obuvki") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Brand.includes("Lacoste")
        );
      } else if (type === "Lacoste Obleka") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Brand.includes("Lacoste")
        );
      } else if (type === "Jack Wolfskin Obuvki") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Brand.includes("Jack Wolf")
        );
      } else if (type === "Jack Wolfskin Oблека") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Brand.includes("Jack Wolf")
        );
      } else if (type === "New Balance Obuvki") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Brand.includes("New Balance")
        );
      } else if (type === "New Balance Obleka") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Brand.includes("New Balance")
        );
      } else if (type === "Helly Hansen Obuvki") {
        return ProductsJson.getMenFootwear().filter((f: Product) =>
          f.Brand.includes("Helly Hansen")
        );
      } else if (type === "Helly Hansen Obleka") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Brand.includes("Helly Hansen")
        );
      }

      //Obleka

      if (type === "Dukseri") {
        return ProductsJson.getMenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("HOODIE") || f.Product_Name.includes("DUKS")
        );
      }

      if (type === "Kompleti") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Product_Name.includes("KOMPLET")
        );
      }
      if (type === "Pantaloni") {
        return ProductsJson.getMenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("PANTALONA") ||
            f.Product_Name.includes("PANTS") ||
            f.Product_Name.includes("DOLNA")
        );
      }
      if (type === "Maici") {
        return ProductsJson.getMenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("MAICA") ||
            f.Product_Name.includes("SHIRT") ||
            f.Product_Name.includes("BLUZA")
        );
      }
      if (type === "Jakni") {
        return ProductsJson.getMenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("JAKNA") ||
            f.Product_Name.includes("JACKET")
        );
      }
      if (type === "Sorcevi") {
        return ProductsJson.getMenApparel().filter((f: Product) =>
          f.Product_Name.includes("SORC")
        );
      }

      if (type === "Obleka") {
        return ProductsJson.getMenApparel();
      }

      //OPREMA

      if (type === "Corapi") {
        return ProductsJson.getMenEquipment().filter((f: Product) =>
          f.Product_Name.includes("CORAPI")
        );
      }
      if (type === "Kapi") {
        return ProductsJson.getMenEquipment().filter(
          (f: Product) =>
            f.Product_Name.includes("KAPA") || f.Product_Name.includes("CAP")
        );
      }
      if (type === "Ranci") {
        return ProductsJson.getMenEquipment().filter((f: Product) =>
          f.Product_Name.includes("RANEC")
        );
      }
      if (type === "Torbi") {
        return ProductsJson.getMenEquipment().filter(
          (f: Product) =>
            f.Product_Name.includes("TORBA") ||
            f.Product_Name.includes("TORBICE")
        );
      }
      if (type === "Narakvici") {
        return ProductsJson.getMenEquipment().filter((f: Product) =>
          f.Product_Name.includes("GLOVE")
        );
      }

      if (type === "Oprema") {
        return ProductsJson.getMenEquipment();
      }
    }

    //Women
    else if (category === "Zeni") {
      ///////////////BRANDS
      if (type === "Nike Obuvki") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Brand.includes("Nike")
        );
      } else if (type === "Nike Obleka") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Brand.includes("Nike")
        );
      } else if (type === "Vans Obuvki") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Brand.includes("Vans")
        );
      } else if (type === "Vans Obleka") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Brand.includes("Vans")
        );
      } else if (type === "Lacoste Obuvki") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Brand.includes("Lacoste")
        );
      } else if (type === "Lacoste Obleka") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Brand.includes("Lacoste")
        );
      } else if (type === "Jack Wolfskin Obuvki") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Brand.includes("Jack Wolf")
        );
      } else if (type === "Jack Wolfskin Oблека") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Brand.includes("Jack Wolf")
        );
      } else if (type === "New Balance Obuvki") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Brand.includes("New Balance")
        );
      } else if (type === "New Balance Obleka") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Brand.includes("New Balance")
        );
      } else if (type === "Color Kids Obuvki") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Brand.includes("Color Kids")
        );
      } else if (type === "Color Kids Obleka") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Brand.includes("Colo Kids")
        );
      } else if (type === "Helly Hansen Obuvki") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Brand.includes("Helly Hansen")
        );
      } else if (type === "Helly Hansen Obleka") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Brand.includes("Helly Hansen")
        );
      }

      //OBUVKI

      if (type === "Air_Max") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Product_Name.includes("AIR MAX")
        );
      } else if (type === "Lina") {
        return ProductsJson.getWomenFootwear().filter((f: Product) =>
          f.Product_Name.includes("LINA")
        );
      } else if (type === "Obuvki") {
        return ProductsJson.getWomenFootwear();
      }

      //OBLEKA

      if (type === "Dukseri") {
        return ProductsJson.getWomenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("HOODIE") || f.Product_Name.includes("DUKS")
        );
      }
      if (type === "Kompleti") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Product_Name.includes("KOMPLET")
        );
      }
      if (type === "Maici") {
        return ProductsJson.getWomenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("MAICA") || f.Product_Name.includes("BLUZA")
        );
      }
      if (type === "Jakni") {
        return ProductsJson.getWomenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("JAKNA") ||
            f.Product_Name.includes("JACKET")
        );
      }
      if (type === "Pantaloni") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Product_Name.includes("DOLNA")
        );
      }
      if (type === "Helanki") {
        return ProductsJson.getWomenApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("HELANKA") ||
            f.Product_Name.includes("HELANKI")
        );
      }
      if (type === "Suskavci") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Product_Name.includes("SUSKAVEC")
        );
      }
      if (type === "Sorcevi") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Product_Name.includes("SORC")
        );
      }
      if (type === "Fustani") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Product_Name.includes("FUSTAN")
        );
      }
      if (type === "Eleci") {
        return ProductsJson.getWomenApparel().filter((f: Product) =>
          f.Product_Name.includes("ELEK")
        );
      }

      if (type === "Obleka") {
        return ProductsJson.getWomenApparel();
      }

      //Oprema

      if (type === "Kapi") {
        return ProductsJson.getWomenEquipment().filter((f: Product) =>
          f.Product_Name.includes("KAPA")
        );
      }

      if (type === "Ranci") {
        return ProductsJson.getWomenEquipment().filter((f: Product) =>
          f.Product_Name.includes("RANEC")
        );
      }

      if (type === "Torbi") {
        return ProductsJson.getWomenEquipment().filter(
          (f: Product) =>
            f.Product_Name.includes("TORBA") ||
            f.Product_Name.includes("TORBICE")
        );
      }

      if (type === "Corapi") {
        return ProductsJson.getWomenEquipment().filter((f: Product) =>
          f.Product_Name.includes("LINA")
        );
      }

      if (type === "Oprema") {
        return ProductsJson.getWomenEquipment();
      }
    }

    //Kids
    else if (category === "Deca") {
      //BRANDS
      if (type === "Air_Max") {
        return ProductsJson.getKidsFootwear().filter((f: Product) =>
          f.Product_Name.includes("AIR MAX")
        );
      } else if (type === "Revolution") {
        return ProductsJson.getKidsFootwear().filter((f: Product) =>
          f.Product_Name.includes("REVOLUTION")
        );
      } else if (type === "Court_Borough") {
        return ProductsJson.getKidsFootwear().filter((f: Product) =>
          f.Product_Name.includes("Court_Borough")
        );
      } else if (type === "Obuvki") {
        return ProductsJson.getKidsFootwear();
      } else if (type === "Vans Obuvki") {
        return ProductsJson.getKidsFootwear().filter((f: Product) =>
          f.Brand.includes("Vans")
        );
      } else if (type === "Vans Obleka") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Brand.includes("Vans")
        );
      } else if (type === "Vans Oprema") {
        return ProductsJson.getKidsEquipment().filter((f: Product) =>
          f.Brand.includes("Vans")
        );
      } else if (type === "New Balance Obleka") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Brand.includes("New Balance")
        );
      } else if (type === "New Balance Obuvki") {
        return ProductsJson.getKidsFootwear().filter((f: Product) =>
          f.Brand.includes("New Balance")
        );
      }

      //Obleka
      if (type === "Dukseri") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("DUKS")
        );
      }
      if (type === "Kompleti") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("KOMPLET")
        );
      }
      if (type === "Pantaloni") {
        return ProductsJson.getKidsApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("PANTS") || f.Product_Name.includes("DOLNA")
        );
      }
      if (type === "Maici") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("MAICA")
        );
      }
      if (type === "Jakni") {
        return ProductsJson.getKidsApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("JAKNA") ||
            f.Product_Name.includes("JACKET")
        );
      }
      if (type === "Suskavci") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("SUSKAVEC")
        );
      }
      if (type === "Fustani") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("FUSTAN")
        );
      }
      if (type === "Sorcevi") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("SORC")
        );
      }
      if (type === "Helanki") {
        return ProductsJson.getKidsApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("HELANKI") ||
            f.Product_Name.includes("HELANKA")
        );
      }
      if (type === "Obleka") {
        return ProductsJson.getKidsApparel();
      }

      //Oprema
      else if (type === "Ranci") {
        return ProductsJson.getKidsEquipment().filter((f: Product) =>
          f.Product_Name.includes("RANEC")
        );
      } else if (type === "Kapi") {
        return ProductsJson.getKidsEquipment().filter((f: Product) =>
          f.Product_Name.includes("KAPA")
        );
      } else if (type === "Narakvici") {
        return ProductsJson.getKidsEquipment().filter((f: Product) =>
          f.Product_Name.includes("GLOVES")
        );
      } else if (type === "Oprema") {
        return ProductsJson.getKidsEquipment();
      } else {
        return [];
      }
    }

    //Little Kids
    else if (category === "Mali_Deca") {
      //Бранд

      if (type === "Color Kids Obuvki") {
        return ProductsJson.getLittleKidsFootwear().filter((f: Product) =>
          f.Brand.includes("Color Kids")
        );
      }

      if (type === "Color Kids Obleka") {
        return ProductsJson.getLittleKidsApparel().filter((f: Product) =>
          f.Brand.includes("Color Kids")
        );
      }

      //Obuvki

      if (type === "Air_Max") {
        return ProductsJson.getLittleKidsFootwear().filter((f: Product) =>
          f.Product_Name.includes("AIR MAX")
        );
      }

      if (type === "Revolution") {
        return ProductsJson.getLittleKidsFootwear().filter((f: Product) =>
          f.Product_Name.includes("REVOLUTION")
        );
      }

      if (type === "Court_Borough") {
        return ProductsJson.getLittleKidsFootwear().filter((f: Product) =>
          f.Product_Name.includes("Court_Borough")
        );
      }

      if (type === "Obuvki") {
        return ProductsJson.getLittleKidsFootwear();
      }

      //Obleka

      if (type === "Maici") {
        return ProductsJson.getLittleKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("MAICA")
        );
      }
      if (type === "Jakni") {
        return ProductsJson.getLittleKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("JAKNA")
        );
      }
      if (type === "Kombinezoni") {
        return ProductsJson.getLittleKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("COVERALL")
        );
      }
      if (type === "Kompleti") {
        return ProductsJson.getLittleKidsApparel().filter((f: Product) =>
          f.Product_Name.includes("KOMPLET")
        );
      }

      if (type === "Obleka") {
        return ProductsJson.getLittleKidsApparel();
      }

      //Oprema

      if (type === "Corapi") {
        return ProductsJson.getLittleKidsEquipment().filter((f: Product) =>
          f.Product_Name.includes("CORAPI")
        );
      }

      if (type === "Ranci") {
        return ProductsJson.getLittleKidsEquipment().filter((f: Product) =>
          f.Product_Name.includes("RANEC")
        );
      }

      if (type === "Oprema") {
        return ProductsJson.getLittleKidsEquipment();
      }
    }

    //Infants Kids
    else if (category === "Bebinja") {
      //Obuvki

      if (type === "Color Kids Obleka") {
        return ProductsJson.getInfantsEquipment().filter((f: Product) =>
          f.Brand.includes("Color Kids")
        );
      }

      if (type === "Color Kids Oprema") {
        return ProductsJson.getInfantsApparel().filter((f: Product) =>
          f.Brand.includes("Color Kids")
        );
      } else if (type === "Air_Max") {
        return ProductsJson.getInfantsFootwear().filter((f: Product) =>
          f.Product_Name.includes("AIR MAX")
        );
      } else if (type === "Revolution") {
        return ProductsJson.getInfantsFootwear().filter((f: Product) =>
          f.Product_Name.includes("REVOLUTION")
        );
      }
      if (type === "Court_Borough") {
        return ProductsJson.getInfantsFootwear().filter((f: Product) =>
          f.Product_Name.includes("Court_Borough")
        );
      }

      if (type === "Obuvki") {
        return ProductsJson.getInfantsFootwear();
      } else if (type === "New Balance Obleka") {
        return ProductsJson.getKidsApparel().filter((f: Product) =>
          f.Brand.includes("New Balance")
        );
      }

      //Obleka

      if (type === "Maici") {
        return ProductsJson.getInfantsApparel().filter((f: Product) =>
          f.Product_Name.includes("MAICA")
        );
      }
      if (type === "Jakni") {
        return ProductsJson.getInfantsApparel().filter((f: Product) =>
          f.Product_Name.includes("JACKET")
        );
      }
      if (type === "Kombinezoni") {
        return ProductsJson.getInfantsApparel().filter(
          (f: Product) =>
            f.Product_Name.includes("COVERALL") ||
            f.Product_Name.includes("WHOLESUIT") ||
            f.Product_Name.includes("BODI")
        );
      }
      if (type === "Kompleti") {
        return ProductsJson.getInfantsApparel().filter((f: Product) =>
          f.Product_Name.includes("KOMPLET")
        );
      }

      if (type === "Obleka") {
        return ProductsJson.getInfantsApparel();
      }

      //Oprema

      if (type === "Corapi") {
        return ProductsJson.getInfantsEquipment().filter((f: Product) =>
          f.Product_Name.includes("CORAPI")
        );
      }

      if (type === "Ranci") {
        return ProductsJson.getInfantsEquipment().filter((f: Product) =>
          f.Product_Name.includes("RANEC")
        );
      }

      if (type === "Kapi") {
        return ProductsJson.getInfantsEquipment().filter((f: Product) =>
          f.Product_Name.includes("HAT")
        );
      }

      if (type === "Oprema") {
        return ProductsJson.getInfantsEquipment();
      }
    } else {
      return [];
    }
  },
};
