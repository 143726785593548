export const sizes = {
  getSizes: (category: string | undefined, type: string | undefined) => {
    let menAndWomenApparel: any[] | undefined;
    menAndWomenApparel = [
      "Dukseri",
      "Kompleti",
      "Pantaloni",
      "Maici",
      "Jakni",
      "Sorcevi",
      "Obleka",
      "Helanki",
      "Suskavci",
      "Fustani",
      "Eleci",
    ];

    let menFootwear: any[] | undefined;
    menFootwear = ["Air_Max", "Jordan", "Obuvki"];

    let womenFootwear: any[] | undefined;
    womenFootwear = ["Air_Max", "Lina", "Obuvki"];

    let kidsFootwear: any[] | undefined;
    kidsFootwear = ["Air_Max", "Revolution", "Court_Borough", "Obuvki"];

    let kidsApparel: any[] | undefined;
    kidsApparel = [
      "Dukseri",
      "Kompleti",
      "Pantaloni",
      "Maici",
      "Jakni",
      "Sorcevi",
      "Obleka",
      "Helanki",
      "Suskavci",
      "Fustani",
      "Eleci",
    ];

    let littleKidsApparel: any[] | undefined;
    littleKidsApparel = ["Maici", "Jakni", "Kompleti", "Kombinezoni", "Obleka"];

    let infantsApparel: any[] | undefined;
    infantsApparel = ["Maici", "Jakni", "Kompleti", "Kombinezoni", "Obleka"];

    if (
      (category === "Mazi" || category === "Zeni") &&
      menAndWomenApparel.includes(type)
    ) {
      return [
        { name: "XS", inStock: true },
        { name: "S", inStock: true },
        { name: "M", inStock: true },
        { name: "L", inStock: true },
        { name: "XL", inStock: true },
        { name: "XXL", inStock: true },
        { name: "XXXL", inStock: true },
      ];
    }

    // MAZI

    if (category === "Mazi" && menFootwear.includes(type)) {
      return [
        { name: "40", inStock: true },
        { name: "40.5", inStock: true },
        { name: "41", inStock: true },
        { name: "42", inStock: true },
        { name: "42.5", inStock: true },
        { name: "43", inStock: true },
        { name: "43.5", inStock: true },
        { name: "44", inStock: true },
        { name: "44.5", inStock: true },
        { name: "45", inStock: true },
        { name: "45.5", inStock: true },
        { name: "46", inStock: true },
      ];
    }
    //ZENI
    if (category === "Zeni" && womenFootwear.includes(type)) {
      return [
        { name: "35.5", inStock: true },
        { name: "36", inStock: true },
        { name: "36.5", inStock: true },
        { name: "37.5", inStock: true },
        { name: "38", inStock: true },
        { name: "38.5", inStock: true },
        { name: "39", inStock: true },
        { name: "39.5", inStock: true },
        { name: "40", inStock: true },
        { name: "40.5", inStock: true },
        { name: "41", inStock: true },
        { name: "42", inStock: true },
      ];
    }

    //Deca

    if (category === "Deca" && kidsFootwear.includes(type)) {
      return [
        { name: "35.5", inStock: true },
        { name: "36", inStock: true },
        { name: "36.5", inStock: true },
        { name: "37", inStock: true },
        { name: "37.5", inStock: true },
        { name: "38", inStock: true },
        { name: "38.5", inStock: true },
        { name: "39", inStock: true },
        { name: "40", inStock: true },
        { name: "40.5", inStock: true },
        { name: "41", inStock: true },
        { name: "42", inStock: true },
      ];
    }

    if (category === "Deca" && kidsApparel.includes(type)) {
      return [
        { name: "9-10Г", inStock: true },
        { name: "10-11Г", inStock: true },
        { name: "11-12Г", inStock: true },
        { name: "12-13Г", inStock: true },
        { name: "13-14Г", inStock: true },
      ];
    }

    //Mali_Deca

    if (category === "Mali_Deca" && kidsFootwear.includes(type)) {
      return [
        { name: "22", inStock: true },
        { name: "23", inStock: true },
        { name: "24", inStock: true },
        { name: "25", inStock: true },
        { name: "26", inStock: true },
        { name: "27", inStock: true },
        { name: "31", inStock: true },
        { name: "28", inStock: true },
        { name: "29", inStock: true },
        { name: "30", inStock: true },
        { name: "31", inStock: true },
        { name: "32", inStock: true },
        { name: "33", inStock: true },
        { name: "34", inStock: true },
        { name: "35", inStock: true },
      ];
    }

    if (category === "Mali_Deca" && littleKidsApparel.includes(type)) {
      return [
        { name: "98cm", inStock: true },
        { name: "104cm", inStock: true },
        { name: "110cm", inStock: true },
        { name: "116cm", inStock: true },
        { name: "122cm", inStock: true },
        { name: "128cm", inStock: true },
      ];
    }

    if (category === "Bebinja" && kidsFootwear.includes(type)) {
      return [
        { name: "17.5", inStock: true },
        { name: "18.5", inStock: true },
        { name: "19", inStock: true },
        { name: "19.5", inStock: true },
        { name: "20", inStock: true },
        { name: "21", inStock: true },
        { name: "22", inStock: true },
        { name: "23.5", inStock: true },
        { name: "24", inStock: true },
        { name: "25", inStock: true },
        { name: "26", inStock: true },
        { name: "27", inStock: true },
      ];
    }

    if (category === "Bebinja" && infantsApparel.includes(type)) {
      return [
        { name: "0-3М", inStock: true },
        { name: "3-6М", inStock: true },
        { name: "6-9М", inStock: true },
        { name: "9-12M", inStock: true },
        { name: "12-18М", inStock: true },
        { name: "18-24M", inStock: true },
        { name: "24-36М", inStock: true },
        { name: "/", inStock: false },
        { name: "68cm", inStock: true },
        { name: "74cm", inStock: true },
        { name: "80cm", inStock: true },
        { name: "86cm", inStock: true },
        { name: "92cm", inStock: true },
        { name: "98cm", inStock: true },
      ];
    }

    // Za bebinja 0-3m 3-6m 6-9m 1g
    // A deca 5 6 7 8 9 10 11 12 13 14

    return [{ name: "Унив", inStock: true }];
  },
};
