import React from "react";
import { Link } from "react-router-dom";
import { brandsCallouts as callouts } from "../../service/constants";

export const Brands: React.FC = () => {
  return (
    <div className="bg-gray-100 text-gray-800  rounded-lg mb-10 backdrop-filter backdrop-blur bg-opacity-75 pt-10 pb-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto  py-5 sm:py-5 lg:py-5 lg:max-w-none">
          <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
            {callouts.map((callout, idx) => (
              <div key={idx} className="group relative">
                <Link to={callout.href}>
                  <div className="relative w-full h-full bg-opacity-0 rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="w-full h-full object-center object-contain"
                    />
                  </div>
                </Link>
                <p className="= font-semibold text-xl mt-5">
                  {callout.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
