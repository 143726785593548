import React from "react";
import { Brands } from "./brands";
import { Categories } from "./categories";
import Incentives from "./incentives";
import { BrandCarousel } from "./brandCarousel";
import { SlickCarousel } from "./slickCarousel";
import { LeftOffer } from "../SpecialOffers/leftOffer";
import { RightOffer } from "../SpecialOffers/rightOffer";
import { Discounts } from "./discounts";

export const Home: React.FC = () => {
  return (
    <div className="Home">
      <main>
        <SlickCarousel />
        <Discounts />
        <div className="grid lg:grid-cols-2  sm:grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <LeftOffer />
          </div>
          <div>
            <RightOffer />
          </div>
        </div>
        <Categories />
        <BrandCarousel />
        <Brands />
        <Incentives />
      </main>
    </div>
  );
};
