import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { brandCarouselBrands as brands } from "../../service/constants";
export const BrandCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 700,
    slidesToShow: 4,
  };
  return (
    <div className="w-3/4 sm:mx-auto mx-auto px-4 sm:px-6 lg:px-8 h-72">
      <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 text-center mb-10">
        Брендови
      </h2>
      <Slider {...settings}>
        {brands.map((bra, indx) => (
          <div key={indx}>
            <Link to={bra.href}>
              <img src={bra.imageSrc} className=" w-36 m-auto " />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};
