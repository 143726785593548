import { Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Dialog, RadioGroup, Tab, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { BreadCrumbs } from "../breadcrumbs/breadcrumbs";
import { TypeOfProductsToReturn } from "../../service/TypeOfProductsToReturn";
import { sizes } from "../../service/sizes";
import { Product } from "../../service/interfaces";
import { Size } from "../../service/interfaces";
import shuffle, { pathDict } from "../../service/constants";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
interface Props {
  open: (
    id: string,
    selectedSize: string,
    qty: number,
    products: Product[]
  ) => void;
}
export const ProductPage: React.FC<Props> = (props: Props) => {
  const { id, category, type } = useParams();
  let articleSizes = sizes.getSizes(category, type);
  let products = TypeOfProductsToReturn.getProducts(category, type);
  const product = products.filter((c: Product) => c.Product_Code === id)[0];
  const relatedProducts = shuffle(products).slice(0, 4);
  const [selectedSize, setSelectedSize] = useState(articleSizes[0]);
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-cipa-blue-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <div className="border-t border-gray-200 py-6 px-4">
                <Link to="#" className="-m-2 p-2 flex items-center">
                  <img
                    src="https://tailwindui.com/img/flags/flag-canada.svg"
                    alt=""
                    className="w-5 h-auto block flex-shrink-0"
                  />
                  <span className="ml-3 block text-base font-medium text-cipa-blue-900">
                    CAD
                  </span>
                  <span className="sr-only">, change currency</span>
                </Link>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <main className="max-w-7xl mx-auto sm:pt-16 sm:px-6 lg:px-8">
        <div className="p-5 items-center justify-center">
          {(() => {
            if (type !== "undefined") {
              return (
                <BreadCrumbs
                  Pages={[
                    {
                      name: category !== undefined ? pathDict[category] : "",
                      href: "/category_page/" + category,
                      current: false,
                    },
                    {
                      name: type !== undefined ? pathDict[type] : "",
                      current: true,
                      href: "#",
                    },
                    { name: product.Product_Name, href: "#", current: true },
                  ]}
                />
              );
            } else {
              return <BreadCrumbs Pages={[]} />;
            }
          })()}
        </div>
        <div className="max-w-2xl mx-auto lg:max-w-none mt-10">
          {/* Product */}
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start mb-32">
            {/* Image gallery */}
            <Tab.Group as="div" className="flex flex-col-reverse">
              {/* Image selector */}
              <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {product.Images.map((image: string, idx: number) => (
                    <Tab
                      key={idx}
                      className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-cipa-blue-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                    >
                      {({ selected }) => (
                        <>
                          <span className="sr-only">{image}</span>
                          <span className="absolute inset-0 rounded-md overflow-hidden">
                            <img
                              src={image}
                              alt=""
                              className="w-full h-full object-center object-cover"
                            />
                          </span>
                          <span
                            className={classNames(
                              selected ? "ring-indigo-500" : "ring-transparent",
                              "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className="w-3/4 aspect-w-1 aspect-h-1 mx-auto">
                {product.Images.map((image: string, idx: number) => (
                  <Tab.Panel key={idx}>
                    <img
                      src={image}
                      className="w-full h-full object-center object-cover sm:rounded-lg"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            {/* Product info */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 className="text-3xl font-extrabold tracking-tight text-cipa-blue-900">
                {/* <span>{product.brand}</span> <br /> */}
                {product.Product_Name}
              </h1>
              <small className="text-gray-500">
                ( {product.Product_Code} )
              </small>

              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <div className="text-base text-cipa-blue-700 space-y-6" />
                {/* {product.description} */}
              </div>

              <form className="mt-6">
                {/* Colors */}
                <div>
                  <h3 className=" text-md text-left mb-5 text-cipa-blue-600">
                    Избери големина
                  </h3>
                  <RadioGroup
                    value={selectedSize}
                    onChange={setSelectedSize}
                    className="mt-2"
                  >
                    <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                      {articleSizes.map((option: Size, idx: number) => (
                        <RadioGroup.Option
                          key={idx}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option.inStock
                                ? "cursor-pointer focus:outline-none"
                                : "opacity-25 cursor-not-allowed",
                              active
                                ? " bg-cipa-red-600 text-white border-2"
                                : "",
                              checked
                                ? "bg-cipa-red-600 border-transparent text-white hover:bg-cipa-red-700"
                                : "bg-white border-gray-200 text-gray-900 ",
                              "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                            )
                          }
                          disabled={!option.inStock}
                        >
                          <RadioGroup.Label as="p">
                            {option.name}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <div className="mt-5 text-left">
                  <label>Количина: </label>
                  <input
                    id="qty"
                    className="border-2 border-cipa-blue-700 w-11 h-10 text-xl text-center rounded-lg"
                    defaultValue="1"
                  />
                </div>

                <div className="mt-10 flex sm:flex-col1 justify-between">
                  <div>
                    <button
                      type="button"
                      className="max-w-xs text-white flex-1 bg-cipa-red-600 hover:bg-cipa-red-700 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-whitefocus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-cipa-blue-500 sm:w-full"
                      onClick={() => {
                        let qty = (
                          document.getElementById("qty") as HTMLInputElement
                        ).value;
                        props.open(
                          product.Product_Code,
                          selectedSize.name,
                          parseInt(qty),
                          products
                        );
                        (
                          document.getElementById("qty") as HTMLInputElement
                        ).value = "1";
                      }}
                    >
                      Додади во кошничка
                    </button>
                  </div>

                  <div>
                    {(() => {
                      if (product.Discount === 0) {
                        if (product.Price > 999) {
                          return (
                            <p className="text-base font-medium text-gray-900">
                              {(product.Price / 1000).toFixed(3)} Ден
                            </p>
                          );
                        } else {
                          return (
                            <p className="text-base font-medium text-gray-900">
                              {product.Price} Ден
                            </p>
                          );
                        }
                      } else {
                        if (product.Price > 999) {
                          return (
                            <div>
                              <p className="text-base font-medium text-black line-through">
                                {(product.Price / 1000).toFixed(3)} Ден
                              </p>
                              <p className="text-base font-medium text-cipa-red-500">
                                -{product.Discount}%
                              </p>

                              <p className="text-base font-medium text-cipa-red-500">
                                {(
                                  Math.round(
                                    product.Price -
                                      product.Price * (product.Discount / 100)
                                  ) / 1000
                                ).toFixed(3)}{" "}
                                Ден
                              </p>
                            </div>
                          );
                        } else {
                          return (
                            <p className="text-base font-medium text-gray-900">
                              {product.Price} Ден
                            </p>
                          );
                        }
                      }
                    })()}
                  </div>
                </div>
              </form>

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>
              </section>
            </div>
          </div>
        </div>
        <section aria-labelledby="related-heading" className="mt-24">
          <h2
            id="related-heading"
            className="text-lg font-medium text-gray-900"
          >
            Слични производи&hellip;
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 mb-10">
            {relatedProducts.map((relatedProduct: Product, idx: number) => (
              <div key={idx} className="group relative">
                <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={relatedProduct.Images[0]}
                    className="w-full h-full object-center object-contain lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 ">
                  <div>
                    <h3 className="text-md font-[500] text-gray-700">
                      <Link
                        to={
                          "/product_page/" +
                          relatedProduct.Category.split(" ")[0] +
                          "/" +
                          relatedProduct.Category.split(" ")[1] +
                          "/" +
                          relatedProduct.Product_Code
                        }
                      >
                        <span aria-hidden="true" className="absolute inset-0" />
                        {relatedProduct.Product_Name}
                      </Link>
                    </h3>
                  </div>

                  <p className="text-sm font-medium text-gray-900">
                    {(() => {
                      if (relatedProduct.Discount === 0) {
                        if (relatedProduct.Price > 999) {
                          return (
                            <p className="text-base font-medium text-center text-gray-900">
                              {(relatedProduct.Price / 1000).toFixed(3)} Ден
                            </p>
                          );
                        } else {
                          return (
                            <p className="text-base font-medium text-center text-gray-900">
                              {relatedProduct.Price} Ден
                            </p>
                          );
                        }
                      } else {
                        if (relatedProduct.Price > 999) {
                          return (
                            <div className="text-center">
                              <p className="text-base font-medium text-cipa-red-500">
                                - {relatedProduct.Discount} %
                              </p>
                              <p className="text-base font-medium text-cipa-red-500">
                                {(relatedProduct.Price / 1000).toFixed(3)} Ден
                              </p>
                            </div>
                          );
                        } else {
                          return (
                            <div className="text-center">
                              <p className="text-base font-medium text-cipa-red-500">
                                - {relatedProduct.Discount} %
                              </p>
                              <p className="text-base font-medium text-cipa-red-500">
                                {relatedProduct.Price} Ден
                              </p>
                            </div>
                          );
                        }
                      }
                    })()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};
