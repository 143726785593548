import React from "react";
import { Link } from "react-router-dom";
import { TypeOfProductsToReturn } from "../../service/TypeOfProductsToReturn";
import { Product } from "../../service/interfaces";

export const Discounts: React.FC = () => {
  let product: Product[] = TypeOfProductsToReturn.getProducts("Mazi", "Obuvki")
    .filter((f: Product) => f.Discount !== 0)
    .slice(0, 6);
  product.sort((a: Product, b: Product) =>
    a.Price - a.Price * (a.Discount / 100) <
    b.Price - b.Price * (b.Discount / 100)
      ? 1
      : -1
  );
  return (
    <div className=" bg-gray-100  text-gray-800 py-10">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-5 ">
        <div className="space-y-12">
          <div className="px-4 sm:px-6 sm:flex sm:items-center sm:justify-between lg:px-8 xl:px-0">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
              Распродажба
            </h2>
            <Link
              to="/discount_list"
              className="hidden text-sm font-semibold text-cipa-red-700 hover:text-cipa-red-500 sm:block"
            >
              Погледни повеќе производи<span aria-hidden="true"> →</span>
            </Link>
          </div>

          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-6 lg:gap-x-8"
          >
            {product.map((product, idx) => (
              <li key={idx}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2 relative">
                    <Link
                      to={
                        "/product_page/" +
                        product.Category.split(" ")[0] +
                        "/" +
                        product.Category.split(" ")[1] +
                        "/" +
                        product.Product_Code
                      }
                    >
                      <img
                        className="object-cover shadow-lg rounded-lg relative"
                        src={product.Images[0]}
                        alt=""
                      />
                      <div className="absolute top-0 right-0 px-2 py-1 bg-cipa-red-500">
                        <span className="text-white">-{product.Discount}%</span>
                      </div>
                    </Link>
                  </div>
                  <div className="space-y-2 md:text-right text-center">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="text-md">{product.Product_Name}</h3>
                      <p>Категорија</p>
                      <hr />
                      <p className="text-black md:text-right text-center text-xs line-through">
                        {(product.Price / 1000).toFixed(3)} Ден
                      </p>
                      <p className="text-cipa-red-600">
                        {Math.round(
                          product.Price -
                            product.Price * (product.Discount / 100)
                        ) / 1000}{" "}
                        Ден
                      </p>
                    </div>
                    <ul role="list" className="flex space-x-5"></ul>
                  </div>
                </div>
              </li>
            ))}
            <div className="mt-10 py-10">
              <Link
                to="/discount_list"
                className=" text-md font-semibold text-cipa-red-700 hover:text-cipa-red-500 md:hidden mt-10"
              >
                Погледни повеќе производи<span aria-hidden="true"> →</span>
              </Link>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
