import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useParams } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import { showcase } from "../../service/showcase";

let categories: any[] | undefined;
let collections: any[] | undefined;

export const Brandshowcase: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { brand } = useParams();
  const brandInfo = showcase.getBrand(brand);
  categories = brandInfo?.categories;
  collections = brandInfo?.collections;

  const firstBanner = brandInfo?.firstBanner;
  // const secondBanner = brandInfo?.secondBanner;
  // const thirdBanner = brandInfo?.thirdBanner;
  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                <div className="flow-root">
                  <Link
                    to="#"
                    className="-m-2 p-2 block font-medium text-gray-900"
                  >
                    Create an account
                  </Link>
                </div>
                <div className="flow-root">
                  <Link
                    to="#"
                    className="-m-2 p-2 block font-medium text-gray-900"
                  >
                    Sign in
                  </Link>
                </div>
              </div>

              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                {/* Currency selector */}
                <form>
                  <div className="inline-block">
                    <label htmlFor="mobile-currency" className="sr-only">
                      Currency
                    </label>
                    <div className="-ml-2 group relative border-transparent rounded-md focus-within:ring-2 focus-within:ring-white">
                      <div className="absolute right-0 inset-y-0 flex items-center pointer-events-none">
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                          className="w-5 h-5 text-gray-500"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M6 8l4 4 4-4"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Hero section */}
      <section>
        <div className="relative bg-white h-96 lg:h-138 ">
          <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
            <img
              src={firstBanner}
              alt=""
              className="md:w-full h-full object-cover "
            />
          </div>
        </div>
        <div className="relative max-w-3xl mx-auto px-6 flex flex-col items-center text-center py-10 lg:px-0 text-cipa-blue-500">
          <h3 className="text-4xl font-extrabold tracking-tight">
            {brandInfo?.slogan}
          </h3>

          <a
            href={brandInfo?.websiteLink}
            className="mt-8 inline-block bg-cipa-blue-700 border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:bg-cipa-blue-400"
          >
            {brandInfo?.btnText}
          </a>
        </div>
      </section>
      <main>
        {/* Category section */}
        <section
          aria-labelledby="category-heading"
          className="pt-16 sm:pt-16 xl:max-w-7xl xl:mx-auto xl:px-8 mb-10"
        >
          <div className="px-4 sm:px-6 sm:items-center lg:px-8 xl:px-0">
            <h2
              id="category-heading"
              className="text-2xl font-extrabold tracking-tight text-gray-900 justify-center mb-10"
            >
              Најново
            </h2>
          </div>

          <div className="mt-4 flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto xl:overflow-visible">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-4 xl:gap-x-8">
                  {categories?.map((category, idx) => (
                    <Link
                      key={idx}
                      to={category.href}
                      className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={category.imageSrc}
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                      <span
                        aria-hidden="true"
                        className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                      />
                      <span className="relative mt-auto text-center text-xl font-bold text-white">
                        {category.name}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Featured section
      
        <section>
        <div className="relative bg-white h-96 lg:h-128 mt-40 ">
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src={secondBanner}
            alt=""
            className="w-full h-full object-center object-cover"
          />
        </div>
      </div>
      <div className="relative max-w-3xl mx-auto px-6 flex flex-col items-center text-center py-10 lg:px-0 text-cipa-blue-500">
          <h3 className="text-4xl font-extrabold tracking-tight">
            New arrivals are here wwww
          </h3>
          <p className="mt-4 text-md ">
            The new arrivals have, well, newly arrived. Check out the latest
            options from our summer small-batch release while they're still in
            stock.
          </p>
          <Link
            to="#"
            className="mt-8 inline-block bg-cipa-blue-700 border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:bg-cipa-blue-400"
          >
            Shop New Arrivals
          </Link>
        </div>
        </section>

        <section>
        <div className="relative bg-white h-96 lg:h-128 mt-20 ">
       
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src={thirdBanner}
            alt=""
            className="w-full h-full object-center object-cover"
          />
        </div>
      
      </div>
      <div className="relative max-w-3xl mx-auto px-6 flex flex-col items-center text-center py-10 lg:px-0 text-cipa-blue-500">
          <h3 className="text-4xl font-extrabold tracking-tight">
            New arrivals are here wwww
          </h3>
          <p className="mt-4 text-md ">
            The new arrivals have, well, newly arrived. Check out the latest
            options from our summer small-batch release while they're still in
            stock.
          </p>
          <Link
            to="#"
            className="mt-8 inline-block bg-cipa-blue-700 border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:bg-cipa-blue-400"
          >
            Shop New Arrivals
          </Link>
        </div>
        </section>


        {/* Collection section */}
        {/* <section
          aria-labelledby="collection-heading"
          className="max-w-xl mx-auto pt-16 px-4 sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8 mb-24"
        >
      

          <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
            {collections?.map((collection,idx) => (
              <Link
                key={idx}
                to={collection.href}
                className="group block"
              >
                <div
                  aria-hidden="true"
                  className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
                >
                  <img
                    src={collection.imageSrc}
                    alt={collection.imageAlt}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <h3 className="mt-4 text-base font-semibold text-gray-900">
                  {collection.name}
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {collection.description}
                </p>
              </Link>
            ))}
          </div>
        </section>  */}
      </main>
    </div>
  );
};
