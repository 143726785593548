import React from "react";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon as XIconOutline } from "@heroicons/react/outline";
import { XIcon as XIconSolid } from "@heroicons/react/solid";
import { BreadCrumbs } from "../breadcrumbs/breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import { Order } from "../../service/interfaces";

interface Props {
  ShoppingCart: Order[];
  RemoveCartTerm: (id: string | undefined) => void;
}

export const ShoppingCart: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState(false);
  const pages = [{ name: "Корисничка кошничка", href: "#", current: true }];
  const products = props.ShoppingCart;
  let Total = 0;
  let navigate = useNavigate();
  props.ShoppingCart.map((p) => {
    Total = Total + p.Price * p.Quantity;
  });
  return (
    <div className="bg-white ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        {/* <Steps Steps={steps} /> */}
        <BreadCrumbs Pages={pages} />
      </div>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIconOutline className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                <div className="flow-root">
                  <Link
                    to="#"
                    className="-m-2 p-2 block font-medium text-gray-900"
                  >
                    Sign in
                  </Link>
                </div>
                <div className="flow-root">
                  <Link
                    to="#"
                    className="-m-2 p-2 block font-medium text-gray-900"
                  >
                    Create account
                  </Link>
                </div>
              </div>

              <div className="border-t border-gray-200 py-6 px-4">
                <Link to="#" className="-m-2 p-2 flex items-center">
                  <img
                    src="https://tailwindui.com/img/flags/flag-canada.svg"
                    alt=""
                    className="w-5 h-auto block flex-shrink-0"
                  />
                  <span className="ml-3 block text-base font-medium text-gray-900">
                    CAD
                  </span>
                  <span className="sr-only">, change currency</span>
                </Link>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <main className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Кошничка
        </h1>

        <form className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">
              Items in your shopping cart
            </h2>

            <ul
              role="list"
              className="border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              {products.map((product, productIdx) => (
                <li key={product.Product_Code} className="flex py-6 sm:py-10">
                  <div className="flex-shrink-0">
                    <img
                      src={product.Images[0]}
                      className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                    />
                  </div>

                  <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                      <div>
                        <div className="flex justify-between">
                          <h3 className="text-sm">
                            <Link
                              to="#"
                              className="font-medium text-gray-700 hover:text-gray-800"
                            >
                              {product.Product_Name}
                            </Link>
                          </h3>
                        </div>
                        <div className="mt-1 mb-2 text-sm text-left">
                          {/* <p className="text-gray-500">Боја: {product.color}</p> */}
                          {product.Size ? (
                            <p className="pl-2 border-l border-gray-200 text-gray-500">
                              Големина: {product.Size}
                            </p>
                          ) : null}
                          {product.Quantity ? (
                            <p className="pl-2 border-l border-gray-200 text-gray-500">
                              Количина: {product.Quantity}
                            </p>
                          ) : null}
                        </div>
     

                        {(() => {
                          if (product.Discount === 0) {
                            if (product.Price > 999) {
                              return (
                                <p className="text-base font-medium text-left text-gray-900">
                                 Цена: {(product.Price / 1000).toFixed(3)} Ден
                                </p>
                              );
                            } else {
                              return (
                                <p className="text-base font-medium text-left text-gray-900">
                                  Цена: {product.Price} Ден
                                </p>
                              );
                            }
                          } else {
                            if (product.Price > 999) {
                              return (
                                <div className="text-left">
                                  <p className="text-base ml-12 font-medium text-cipa-red-500">
                                    - {product.Discount} %
                                  </p>
                                  <p className="text-base font-medium text-cipa-red-500">
                                  Цена: {(product.Price / 1000).toFixed(3)} Ден
                                  </p>
                                </div>
                              );
                            } else {
                              return (
                                <div className="text-left">
                                  <p className="text-base ml-12 font-medium text-cipa-red-500">
                                    - {product.Discount} %
                                  </p>
                                  <p className="text-base font-medium text-cipa-red-500">
                                  Цена: {product.Price} Ден
                                  </p>
                                </div>
                              );
                            }
                          }
                        })()}
                      </div>

                      <div className="mt-4 sm:mt-0 sm:pr-9">
                        <label
                          htmlFor={`quantity-${productIdx}`}
                          className="sr-only"
                        >
                          Quantity {product.Quantity}, {product.Product_Name}
                        </label>
                        <div className="absolute top-0 right-0">
                          <button
                            type="button"
                            className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                            onClick={() =>
                              props.RemoveCartTerm(product.Product_Code)
                            }
                          >
                            <span className="sr-only">Remove</span>
                            <XIconSolid
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
          >
            <h2
              id="summary-heading"
              className="text-lg font-medium text-gray-900"
            >
              Нарачка
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="text-base font-medium text-cipa-red-600">
                  Вкупно
                </dt>
                <dd className="text-base font-medium text-cipa-red-600">
                  {(Total / 1000).toFixed(3)} Ден
                </dd>
              </div>
            </dl>

            <div className="mt-6">
              <button
                type="button"
                className="w-full bg-cipa-blue-700 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-cipa-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-cipa-blue-400"
                onClick={() => {
                  navigate("/check_out");
                }}
              >
                Нарачај
              </button>
            </div>
          </section>
        </form>

        {/* Related products */}
        {/* <section aria-labelledby="related-heading" className="mt-24">
          <h2
            id="related-heading"
            className="text-lg font-medium text-gray-900"
          >
            Слични производи&hellip;
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {relatedProducts.map((relatedProduct, idx) => (
              <div key={idx} className="group relative">
                <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={relatedProduct.imageSrc}
                    alt={relatedProduct.imageAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <Link to={relatedProduct.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {relatedProduct.name}
                      </Link>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {relatedProduct.color}
                    </p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    {relatedProduct.price}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section> */}
      </main>
    </div>
  );
};
